<script setup>
import { onMounted } from 'vue';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import { PICKUP_DISPLAY_NUMBER } from '@/consts/discover';

const { fetchPickupProjects, loading, pagination, projects } =
  useFetchProjects();

// ページングしないため初回のみ取得
onMounted(() => {
  fetchPickupProjects({ perPage: PICKUP_DISPLAY_NUMBER });
});
</script>

<template>
  <ViewBase
    v-model:pagination="pagination"
    class="picked-up-view"
    :loading
    :per-page="PICKUP_DISPLAY_NUMBER"
    :projects
  />
</template>
