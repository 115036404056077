import client from '@/modules/api/createClient';

const getFriendInviteCode = () =>
  client.makuakeApiClient.get(`/v2/friendinvite/code`);

const postFriendInvite = friendInviteCode =>
  client.makuakeApiClient.post(`/v2/friendinvite`, {
    friend_invite_code: friendInviteCode,
  });

export default {
  getFriendInviteCode,
  postFriendInvite,
};
