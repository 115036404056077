import { computed, ref } from 'vue';
import UA from '@/modules/UserAgent';

const ua = new UA();
const name = ref('');
const loaded = ref(false);
let initPromise = null;

export default function useDevice() {
  const loadDevice = () => {
    // 初期化が未実行の場合のみ実行
    if (!initPromise) {
      initPromise = (async () => {
        await ua.init();

        if (ua.isNative()) name.value = 'app';
        else if (ua.isSP()) name.value = 'sp';
        else if (ua.isPC()) name.value = 'pc';

        loaded.value = true;
      })();
    }

    return initPromise; // 初期化プロミスを返す
  };

  loadDevice();

  const pc = computed(() => name.value === 'pc');
  const sp = computed(() => name.value === 'sp');
  const app = computed(() => name.value === 'app');

  return {
    app,
    loaded,
    loadDevice,
    name,
    pc,
    sp,
  };
}
