import Cookie from 'js-cookie';

// この関数はログイン直後などに呼び出された場合に, 誤った結果を返すことがあります
// より正確なログイン状態が欲しい場合は,
// `@/modules/api/php/me` モジュールの fetchIsLogin関数を使う方が安全です
export default {
  /**
   * is_loginedのクッキの値を返却する
   *
   * @return {boolean}
   */
  isUserLoggedin: () => Cookie.get('is_logined') === 'true',
};
