<script setup>
import {
  MButton,
  MChip,
  MIcon,
  MSkeleton,
  MText,
} from '@ca-crowdfunding/makuake-ui-n';
import { ref } from 'vue';
import AppLink from '@/components/common/AppLink';
import ScrollContainer from '@/components/common/ScrollContainer';
import MyTagDialog from '@/components/favorite/MyTagDialog';
import MyTagEmpty from '@/components/favorite/MyTagEmpty';
import useMyTag from '@/composables/favorite/myTag';
import { ROUTES } from '@/consts/navigation';

defineProps({ loggedIn: { type: Boolean, required: true } });
const emit = defineEmits(['fetch']);

const { isLoaded, myTags } = useMyTag();

const editing = ref(false);
</script>

<template>
  <div class="relative">
    <MyTagEmpty
      v-if="!loggedIn || (isLoaded && !myTags.length)"
      class="mt-10"
      :logged-in
      @edit="editing = true"
    />
    <template v-else>
      <template v-if="!isLoaded || myTags.length">
        <ScrollContainer v-if="!isLoaded" class="gap-2">
          <MSkeleton
            v-for="key in 4"
            :key
            height="40"
            rounded="full"
            :width="Math.floor(Math.random() * 6 + 9) * 8"
          />
        </ScrollContainer>
        <ScrollContainer v-else class="gap-2 py-2 -my-2 pr-28" tag="ul">
          <li v-for="tag in myTags" :key="tag.id">
            <AppLink
              class="flex rounded-full hover:bg-secondary hover:no-underline"
              :to="{ name: ROUTES.TAG.name, params: { tagId: tag.id } }"
              :tracking-id="`tag_${tag.id}`"
            >
              <MChip size="x-large" variant="outlined">
                <template #label>
                  <MText
                    class="truncate flex items-center"
                    size="x-small"
                    sm="small"
                    weight="bold"
                    tag="span"
                  >
                    {{ tag.name }}
                    <MIcon name="right" />
                  </MText>
                </template>
              </MChip>
            </AppLink>
          </li>
        </ScrollContainer>
        <div
          class="gradient-bg flex h-full w-28 absolute top-0 -right-6 pointer-events-none"
        >
          <div class="w-1/2 ml-auto pointer-events-auto" />
        </div>
        <MSkeleton
          v-if="!isLoaded"
          class="absolute top-0 right-0"
          height="40"
          rounded="full"
          width="64"
        />
        <MButton
          v-else
          class="absolute top-0 right-0 mt-2"
          size="small"
          rounded
          @click="editing = true"
          >編集</MButton
        >
      </template>
    </template>
    <MyTagDialog v-if="loggedIn" v-model="editing" @fetch="emit('fetch')" />
  </div>
</template>

<style scoped>
.gradient-bg {
  background: linear-gradient(
    90deg,
    rgba(255 255 255 / 0%),
    rgba(255 255 255 / 80%) 16%,
    #fff 40%
  );
}

/* TODO: MSkeleton が twMarge に対応後削除 */
.absolute {
  position: absolute !important;
}

.right-0 {
  right: 0;
}

.-right-6 {
  right: -1.5rem;
}

.top-0 {
  top: 0;
}

.pointer-events-auto {
  pointer-events: auto;
}
</style>
