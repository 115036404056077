import { createClient } from '@/modules/api/createClient';

const fetchArticles = () =>
  createClient({
    baseURL: '/api',
  }).get('/blog/articles');

export default {
  fetchArticles,
};
