<script setup>
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';

const { fetchComingSoonProjects, loading, pagination, projects } =
  useFetchProjects();
</script>

<template>
  <ViewBase
    v-model:pagination="pagination"
    coming-soon
    class="coming-soon-view"
    :loading
    :projects
    @fetch="fetchComingSoonProjects({ page: pagination.page })"
  />
</template>
