<script setup>
import { MChip, MText, useDisplay } from '@ca-crowdfunding/makuake-ui-n';

defineProps({
  comingSoon: { type: Boolean, default: false },
  finished: { type: Boolean, default: false },
  store: { type: Boolean, default: false },
});

const { xs } = useDisplay();
</script>

<template>
  <MChip
    class="w-fit"
    :color="store ? 'blue' : 'secondary'"
    :density="xs ? 'compact' : 'comfortable'"
    size="x-small"
    variant="flat"
  >
    <template #label>
      <slot>
        <MText
          :color="store ? 'white' : 'primary'"
          size="3x-small"
          sm="x-small"
          tag="span"
          weight="bold"
        >
          {{
            comingSoon
              ? 'COMING SOON'
              : store
                ? 'STORE'
                : finished
                  ? '終了'
                  : '実施中'
          }}
        </MText>
      </slot>
    </template>
  </MChip>
</template>
