import client from '@/modules/api/createClient';

/**
 * @typedef {{
 * projects: {
 *   id: number;
 *   collected_money: number;
 *   collected_supporter: number;
 *   expiration_date: number;
 *   time_left_label: string;
 *   percent: number;
 *   image_url: string;
 *   title: string;
 *   url: string;
 *   is_new: boolean;
 *   is_store_opening: boolean;
 *   has_target_money: boolean;
 *   has_expiration: boolean;
 *   is_accepting_support: boolean;
 *   hide_collected_money: boolean;
 *   user: {
 *     id: number;
 *     name: string;
 *     image_url: string;
 *     is_selected_user: boolean;
 *   };
 * }[];
 * pagination: {
 *   page: number;
 *   per_page: number;
 *   total: number;
 * };
 * }} FetchProjectsResponse
 */

/**
 * @param {
 * type: 'keyword' | 'maybe-missing';
 * keyword?: string;
 * page?: number;
 * per_page?: number;
 * }
 * @returns {Promise<import('axios').AxiosResponse<FetchProjectsResponse>>}
 */
const fetch = params =>
  client.phpApiClient.get('/projects', { params: { ...params } });

/**
 * `keyword` に関連するプロジェクトを取得する
 *
 * @param {string} keyword
 * @param {{
 * page?: number;
 * perPage?: number;
 * }} options
 */
const fetchProjectsByKeyword = (keyword, { page, perPage } = {}) =>
  fetch({
    type: 'keyword',
    keyword,
    page,
    per_page: perPage,
  });

export default {
  fetchProjectsByKeyword,
};
