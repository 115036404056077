<script setup>
import { computed } from 'vue';
import { MCard, MImg } from '@ca-crowdfunding/makuake-ui-n';

const props = defineProps({
  alt: {
    type: String,
    default: '',
  },
  aspectRatio: {
    type: String,
    required: true,
  },
  eager: {
    type: Boolean,
    default: false,
  },
  heightLg: {
    type: [String, Number],
  },
  heightMd: {
    type: [String, Number],
  },
  heightSm: {
    type: [String, Number],
  },
  height: {
    type: [String, Number],
  },
  src: {
    type: String,
    default: '',
  },
  widthLg: {
    type: [String, Number],
  },
  widthMd: {
    type: [String, Number],
  },
  widthSm: {
    type: [String, Number],
  },
  width: {
    type: [String, Number],
  },
});

const style = computed(() => {
  const [width, height] = props.aspectRatio.split('/');
  return {
    paddingBottom: `${((Number(height) / Number(width)) * 100).toFixed(6)}%`,
  };
});
</script>

<template>
  <div class="thumbnail-card flex relative">
    <div class="sizer h-0" :style="style" />
    <MCard
      class="thumbnail-wrapper flex items-center justify-center p-0"
      color="secondary"
    >
      <slot name="image">
        <MImg
          :alt="alt"
          class="thumbnail"
          :eager="eager"
          :height-lg="heightLg"
          :height-md="heightMd"
          :height-sm="heightSm"
          :height="height"
          :src="src"
          :width-lg="widthLg"
          :width-md="widthMd"
          :width-sm="widthSm"
          :width="width"
        />
      </slot>
      <slot name="text" />
    </MCard>
  </div>
</template>

<style scoped>
.sizer {
  display: block;
  flex: 1 0 0;
  pointer-events: none;
}

.thumbnail-wrapper {
  flex: 1 0 0;
  inset: 0;
  position: absolute;
}

.thumbnail :deep(img) {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}
</style>
