<script setup>
import { computed } from 'vue';
import { MAvatar } from '@ca-crowdfunding/makuake-ui-n';
import SelectedOwnerMark from '@/components/common/SelectedOwnerMark';

const props = defineProps({
  alt: {
    type: String,
    required: true,
  },
  isSelectedOwner: {
    type: Boolean,
    default: false,
  },
  size: {
    type: [String, Number],
    required: true,
  },
  sm: {
    type: [String, Number],
  },
  src: {
    type: String,
    required: true,
  },
});

const windowSize = window.innerWidth;

const iconSize = computed(() =>
  windowSize <= 640 ? Number(props.size) : Number(props.sm || props.size),
);

// see: https://www.figma.com/file/92Un2NEyR7uxqlYX9b7uOA/Makuake-Style-Guide?type=design&node-id=7397-347&mode=design&t=zKXaES65efK5mosU-0
const ownerIconSize = computed(() =>
  iconSize.value < 40
    ? `${Math.round(iconSize.value / (4 - (40 - iconSize.value) / 16)) * 2}px`
    : `${Math.round(iconSize.value / 8) * 4}px`,
);

const ownerIconPosition = computed(
  () =>
    `-${Math.floor((iconSize.value + Math.max(0, 88 - iconSize.value) / 4) / 20) * 2}px`,
);
</script>

<template>
  <div class="owner-avatar relative shrink-0">
    <MAvatar :alt :size :sm :src />
    <div v-if="isSelectedOwner" class="icon-wrapper absolute">
      <SelectedOwnerMark />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.icon-wrapper {
  left: v-bind(ownerIconPosition);
  top: v-bind(ownerIconPosition);

  & > svg {
    height: auto;
    width: v-bind(ownerIconSize);
  }
}
</style>
