import { computed, toValue } from 'vue';
import useFirstVisitForABTest from '@/composables/common/firstVisitForABTest';

/**
 * TODO:あなたへのおすすめセクション表示件数のABテスト（PC）が終わったら削除
 * https://makuake.atlassian.net/browse/PX-2671
 * @param {Ref<boolean>|boolean} isPC
 * @returns {Object}
 */
export default function useABTestPX2671(isPC) {
  const testName = 'PX2671';
  const variants = [
    { variant: 'A', weight: 1 },
    { variant: 'B', weight: 1 },
    { variant: 'C', weight: 1 },
  ];

  const { getSavedVariant } = useFirstVisitForABTest(testName, variants);

  const px2671Variant = computed(() => {
    if (!toValue(isPC)) return undefined;
    return getSavedVariant();
  });

  const px2671DisplayNumber = computed(() =>
    px2671Variant.value === 'C' ? 20 : 10,
  );

  const px2671DisplayRows = computed(() =>
    px2671Variant.value === 'C' ? 4 : 2,
  );

  const isPX2671NonDefaultDisplay = computed(
    () =>
      toValue(isPC) &&
      px2671Variant.value &&
      ['B', 'C'].includes(px2671Variant.value),
  );

  return {
    isPX2671NonDefaultDisplay,
    px2671DisplayNumber,
    px2671DisplayRows,
    px2671Variant,
  };
}
