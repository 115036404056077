import { LOCATIONS } from '@/consts/navigation';

export default function useLocation() {
  const generateIdMap = locations => {
    const idMap = {};

    const traverse = (obj, ancestorNames = []) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === 'object') {
          idMap[key] = [];
          traverse(value, [...ancestorNames, key]);
        } else {
          idMap[key] = [value];
          ancestorNames.forEach(name => {
            idMap[name] = [...idMap[name], value];
          });
        }
      });
    };

    traverse(locations);

    return idMap;
  };

  const getTitleByIds = input => {
    const ids = Array.isArray(input) ? input : input.split(',').map(Number);
    const resultNames = new Set();

    const idMap = generateIdMap(LOCATIONS);
    let remainingIds = [...ids];

    Object.keys(idMap).forEach(key => {
      if (idMap[key].every(id => remainingIds.includes(id))) {
        resultNames.add(key);
        remainingIds = remainingIds.filter(id => !idMap[key].includes(id));
      }
    });

    return [...resultNames].join('・');
  };

  const getAreas = () => {
    const areas = [];

    const traverse = locations => {
      Object.entries(locations).forEach(([key, value]) => {
        if (typeof value === 'object') {
          const hasLeafNodes = Object.values(value).every(
            v => typeof v === 'number',
          );
          if (hasLeafNodes)
            areas.push({
              name: key,
              locations: Object.entries(value).map(([name, id]) => ({
                id,
                name,
              })),
            });
          else traverse(value, key);
        }
      });
    };

    traverse(LOCATIONS);
    return areas;
  };

  return {
    getTitleByIds,
    getAreas,
  };
}
