<script setup>
import { onMounted, ref } from 'vue';
import { MSelect } from '@ca-crowdfunding/makuake-ui-n';
import RankLabel from '@/components/common/RankLabel';
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import RANKING_CATEGORIES from '@/consts/ranking';

const container = ref(null);
const selectedValue = ref(RANKING_CATEGORIES[0].value);

const { fetchRankedProjects, loading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

const fetchProjects = async () => {
  await fetchRankedProjects({ tagGroup: selectedValue.value });
  container.value?.scrollTo(0, 0);
};

setupIntersection(fetchRankedProjects);

onMounted(() => {
  container.value = document.querySelector('.ranking-section ol');
});
</script>

<template>
  <ProjectSectionBase
    container-tag="ol"
    href="/discover/ranking"
    label="ranking"
    :loading
    :projects
    show
    title="今日のランキング"
  >
    <template #[`title.append`]>
      <MSelect
        v-model="selectedValue"
        aria-label="グループの選択"
        class="font-bold"
        density-sm="compact"
        :options="RANKING_CATEGORIES"
        size="small"
        sm="medium"
        variant="tonal"
        @change="fetchProjects"
      />
    </template>
    <template #[`project.thumbnail.append`]="{ project }">
      <RankLabel v-if="project?.rank" :rank="project.rank" />
    </template>
  </ProjectSectionBase>
</template>
