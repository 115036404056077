import { ref } from 'vue';
import goodApi from '@/modules/api/php/good';
import meApi from '@/modules/api/php/me';

const likedIds = ref(/** @type {number[]} */ []);
const isLoaded = ref(false);
let initPromise = null;

export default function useLikedProjectIds() {
  /**
   * @returns {Promise<void>}
   */
  const fetchIds = async () => {
    try {
      const res = await goodApi.fetchGoods();
      if (res.data?.goods) {
        likedIds.value = res.data.goods.split(',').map(Number);
      } else if (res.data?.liked_project_ids) {
        likedIds.value = res.data.liked_project_ids;
      } else {
        likedIds.value = [];
      }
    } catch {
      likedIds.value = [];
    } finally {
      isLoaded.value = true;
    }
  };

  const loadIdsFromCookie = () => {
    const cookieValue = goodApi.getIds();
    // string[]なのでnumber[]に変換する
    likedIds.value = cookieValue.map(Number);
    isLoaded.value = true;
  };

  const loadLikedProjectIds = () => {
    // 初期化が未実行の場合のみ実行
    if (!initPromise) {
      initPromise = (async () => {
        const { data } = await meApi.fetchIsLogin();
        const isLoggedIn = data?.is_logined ?? false;

        if (isLoggedIn) {
          await fetchIds();
        } else {
          loadIdsFromCookie();
        }
      })();
    }

    return initPromise; // 初期化プロミスを返す
  };

  loadLikedProjectIds();

  return {
    isLoaded,
    likedIds,
    loadLikedProjectIds,
  };
}
