<script setup>
import { computed, inject, watch, toValue } from 'vue';
import { MIcon, MButton, MText } from '@ca-crowdfunding/makuake-ui-n';
import useGaEvent from '@/composables/common/gaEvent';
import useMyTag from '@/composables/favorite/myTag';

const props = defineProps({
  tagId: {
    type: [String, Number],
    required: true,
  },
});

const heading = inject('heading');
const { sendClickEvent } = useGaEvent();
const { handleClick, initialize, myTags } = useMyTag();

const tagId = computed(() => Number(props.tagId));
const isRegistered = computed(() =>
  myTags.value.some(tag => tag.id === tagId.value),
);

const onClick = () => {
  const prefix = isRegistered.value ? 'unregister' : 'register';
  sendClickEvent(`${prefix}_tag`);
  // myTags 配列に name をもたせるため heading を用いる
  handleClick(toValue(tagId), toValue(heading).replace(/^#\s*/, ''));
};

watch(tagId, () => initialize(tagId.value), { immediate: true });
</script>

<template>
  <MButton
    class="-m-2"
    color="info"
    size="x-small"
    variant="plain"
    @click="onClick"
  >
    <template #label>
      <MText
        class="flex items-center gap-1"
        color="current"
        size="small"
        sm="medium"
        tag="span"
      >
        <MIcon :name="isRegistered ? 'removeCircle' : 'addCircle'" />
        Myタグ{{ isRegistered ? '解除' : '登録' }}
      </MText>
    </template>
  </MButton>
</template>
