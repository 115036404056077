<script setup>
import { format, fromUnixTime } from 'date-fns';
import { computed } from 'vue';
import {
  MIcon,
  MSkeleton,
  MText,
  useDisplay,
} from '@ca-crowdfunding/makuake-ui-n';
import LikeButton from '@/components/common/LikeButton';
import OwnerAvatar from '@/components/common/OwnerAvatar';
import ProjectLabel from '@/components/common/ProjectLabel';
import SuccessBar from '@/components/common/SuccessBar';
import ThumbnailCard from '@/components/home/ThumbnailCard';
import useGaEvent from '@/composables/common/gaEvent';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';

const props = defineProps({
  comingSoon: { type: Boolean, default: false },
  index: { type: Number },
  loading: { type: Boolean, default: false },
  project: { type: Object, default: null },
  trackingId: { type: String },
});

const { xs } = useDisplay();
const { sendClickEvent, sendSelectContentEvent } = useGaEvent();
const suffix = computed(() => (props.trackingId ? `_${props.trackingId}` : ''));

const onClick = () => {
  sendClickEvent(`project${suffix.value}`, props.index);
  sendSelectContentEvent(props.project.id, SELECT_CONTENT_TYPES.PROJECT);
};
</script>

<template>
  <template v-if="loading">
    <MSkeleton aspect-ratio="16 / 9" class="mb-4" rounded />
    <div class="flex gap-3 px-1 sm:px-0">
      <MSkeleton v-if="!xs" rounded="full" size="40" />
      <div class="grow flex flex-col gap-4">
        <MSkeleton :lines="xs ? 2.4 : 2" size="2x-small" sm="medium" />
        <div class="flex items-center justify-between">
          <slot name="loader.subtext">
            <MSkeleton
              v-if="!comingSoon"
              class="grow"
              lines="0.5"
              size="small"
              sm="large"
            />
            <ProjectLabel v-else class="w-24" coming-soon>
              <span />
            </ProjectLabel>
          </slot>
          <MIcon
            class="shrink-0"
            color="disabled"
            name="heart"
            size="small"
            sm="medium"
          />
        </div>
        <slot name="loader.status">
          <div
            v-if="!comingSoon"
            class="flex items-center gap-2 -mt-1 sm:gap-4 sm:-mt-0.5"
          >
            <MIcon
              class="-mr-1.5 sm:-mr-3"
              size="11"
              sm="small"
              color="disabled"
              name="time"
            />
            <MSkeleton
              class="w-5 sm:w-7"
              lines="1"
              size="3x-small"
              sm="small"
            />
            <SuccessBar />
            <MSkeleton
              class="w-7 sm:w-10"
              lines="1"
              size="3x-small"
              sm="small"
            />
          </div>
        </slot>
      </div>
    </div>
  </template>
  <a
    v-else-if="project"
    class="project-item"
    :href="project.url"
    @click="onClick"
  >
    <article class="contents">
      <ThumbnailCard
        v-if="project.image_url"
        aspect-ratio="16 / 9"
        class="mb-4"
        height="126"
        height-sm="252"
        :src="project.image_url"
        width="224"
        width-sm="448"
      >
        <template #text>
          <slot name="thumbnail.append" :project />
        </template>
      </ThumbnailCard>
      <div class="flex gap-3 px-1 sm:px-0">
        <OwnerAvatar
          v-if="!xs && project.user?.image_url"
          :alt="project.user.name"
          :is-selected-owner="project.user.is_selected_user"
          size="40"
          :src="project.user.image_url"
        />
        <div class="grow flex flex-col gap-4">
          <MText
            :class="xs ? 'min-h-[3.1875rem]' : 'min-h-[2.75rem]'"
            :max-lines="xs ? 3 : 2"
            size="2x-small"
            sm="medium"
            tag="h3"
          >
            {{ project.title }}
          </MText>
          <div class="flex items-center justify-between">
            <slot name="subtext" :project>
              <MText
                v-if="!comingSoon"
                size="small"
                sm="large"
                tag="p"
                weight="bold"
              >
                {{
                  project.collected_money?.toLocaleString('ja-JP', {
                    currency: 'JPY',
                    style: 'currency',
                  })
                }}
              </MText>
              <ProjectLabel v-else coming-soon />
            </slot>
            <LikeButton
              :index
              :project-id="project.id"
              :size="xs ? 'small' : 'medium'"
            />
          </div>
          <slot name="status" :project>
            <div
              v-if="!comingSoon"
              class="flex items-center gap-2 -mt-1 sm:gap-4 sm:-mt-0.5"
            >
              <MIcon
                class="-mr-1.5 sm:-mr-3"
                size="11"
                sm="small"
                color="secondary"
                name="time"
              />
              <MText
                v-if="project.time_left_label"
                size="3x-small"
                sm="small"
                tag="time"
                :datetime="
                  project.expiration_date
                    ? format(
                        fromUnixTime(project.expiration_date),
                        'yyyy-MM-dd',
                      )
                    : ''
                "
              >
                {{ project.time_left_label }}</MText
              >
              <SuccessBar :percent="project.percent" />
              <MText size="3x-small" sm="small" tag="span"
                >{{ project.percent }}%</MText
              >
            </div>
          </slot>
          <ProjectLabel v-if="project.is_new_store_opening" store />
        </div>
      </div>
    </article>
  </a>
</template>

<style lang="scss" scoped>
.project-item:hover {
  text-decoration: none;

  &:not(:has(.like-button:hover)) .thumbnail-card {
    opacity: 0.72;
  }
}

.thumbnail-card {
  transition: opacity 0.2s;
}

.min-h-\[2\.75rem\] {
  min-height: 2.75rem; /* 44px */
}

.min-h-\[3\.1875rem\] {
  min-height: 3.1875rem; /* 51px */
}

/* TODO: MChip が twMerge に対応後に削除 */
.w-24 {
  width: 6rem; /* 96px */
}
</style>
