<script setup>
import { register } from 'swiper/element/bundle';
import { computed, nextTick, onBeforeMount, onUpdated, ref } from 'vue';
import { useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import HeroItem from '@/components/home/HeroItem';
import HeroNavigation from '@/components/home/HeroNavigation';
import api from '@/modules/api/v2/heroes';

import 'swiper/element/css/autoplay';
import 'swiper/element/css/mousewheel';
import 'swiper/element/css/pagination';

// Swiper のカスタム要素を登録
register();

const id = 'hero-container';
const prevClass = 'hero-prev';
const nextClass = 'hero-next';

const swiperParams = {
  a11y: {
    // ナビゲーションのaria-controls属性用に必要
    id,
  },
  autoplay: {
    delay: 4000,
    // ユーザー操作後は自動再生を停止する
    // Swiperの仕様と実装で初期値が異なるので不具合の可能性が高く、
    // 改善されるようであれば削除する
    disableOnInteraction: true,
    pauseOnMouseEnter: true,
  },
  breakpoints: {
    640: {
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      },
      spaceBetween: 24,
    },
    960: {
      centeredSlides: false,
      loop: false,
      pagination: false,
      spaceBetween: 24,
    },
  },
  centeredSlides: true,
  loop: true,
  mousewheel: {
    // マウスホイール操作は水平方向のみ有効にする
    forceToAxis: true,
  },
  navigation: {
    nextEl: `.${nextClass}`,
    prevEl: `.${prevClass}`,
  },
  pagination: {
    el: '.swiper-pagination',
  },
  slidesPerView: 'auto',
  spaceBetween: 16,
  speed: 400,
  threshold: 16,
};

const heroes = ref([]);

const fetchHeroes = async () => {
  const { data } = await api.fetchProjects();
  if (data?.heroes?.length) heroes.value = data.heroes;
};

onBeforeMount(fetchHeroes);

const initSwiper = () => {
  const swiperEl = document.getElementById(id);
  if (swiperEl) {
    // 取得した件数に応じてループを無効化する
    if (heroes.value.length) {
      const width = Math.max(1, 512 * (heroes.value.length - 1));
      const noLoop = {
        centeredSlides: false,
        loop: false,
        slidesOffsetAfter: 24,
        slidesOffsetBefore: 24,
        spaceBetween: width < 640 ? 16 : 24,
      };

      if (width <= 1920) {
        swiperParams.breakpoints[width] = {
          ...swiperParams.breakpoints[width],
          ...noLoop,
        };
      }

      if (width <= 640) {
        swiperParams.breakpoints[640] = {
          ...swiperParams.breakpoints[640],
          ...noLoop,
        };
      }
    }
    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();
  }
};

const { smAndUp } = useDisplay();
const width = computed(() => `${smAndUp.value ? 512 : 320}px`);
const bulletSize = computed(() => `${smAndUp.value ? 0.5 : 0.375}rem`);

onUpdated(() => {
  nextTick(() => {
    initSwiper();
  });
});
</script>

<template>
  <section
    v-if="heroes.length"
    class="hero-area overflow-hidden -mx-6 -mb-6 pb-6 md:-ml-0"
  >
    <div class="relative md:pr-6">
      <swiper-container :id init="false">
        <swiper-slide v-for="(hero, index) in heroes" :key="index">
          <HeroItem :hero :index />
        </swiper-slide>
      </swiper-container>
      <HeroNavigation :next-class :prev-class />
      <div
        class="swiper-pagination flex items-center justify-center mt-6 -mb-6 md:hidden"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
::part(container) {
  overflow: visible;
}

swiper-slide {
  height: auto;
  max-width: calc(100% - 2rem);
  width: v-bind(width);
}

.swiper-pagination {
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-inactive-color: #222;
  --swiper-pagination-bullet-inactive-opacity: 0.16;
  --swiper-pagination-bullet-size: v-bind(bulletSize);
  --swiper-pagination-color: #222;
  --swiper-pagination-bullet-horizontal-gap: 0.25rem;

  position: static;
}

:deep(.swiper-button-prev),
:deep(.swiper-button-next) {
  top: calc(v-bind(width) / 16 * 9 / 2);
}
</style>
