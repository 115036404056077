<script setup>
import { computed, ref, toValue } from 'vue';
import { useDisplay } from '@ca-crowdfunding/makuake-ui-n';

const container = ref(null);

const props = defineProps({
  tag: {
    type: String,
    default: 'div',
  },
  paddingX: {
    type: [Number, String],
    default: 6,
  },
  paddingXSm: {
    type: [Number, String],
    default: 6,
  },
});

const { xs } = useDisplay();

const scrollPaddingX = computed(
  () => `${Number(toValue(xs) ? props.paddingX : props.paddingXSm) / 4}rem`,
);

defineExpose({ container });
</script>

<template>
  <Component
    :is="tag"
    ref="container"
    :class="`scroll-container flex -mx-6 px-${xs ? props.paddingX : props.paddingXSm}`"
  >
    <slot />
  </Component>
</template>

<style lang="scss" scoped>
.scroll-container {
  overflow-x: auto;
  scroll-padding: 0 v-bind(scrollPaddingX);
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > :deep(*) {
    scroll-snap-align: start;
  }
}
</style>
