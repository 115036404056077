<script setup>
import { MText } from '@ca-crowdfunding/makuake-ui-n';
import useGaEvent from '@/composables/common/gaEvent';

const { sendClickEvent } = useGaEvent();
</script>

<template>
  <MText
    color="info"
    href="/my/settings/mail"
    size="small"
    sm="medium"
    @click="sendClickEvent('mail_settings')"
  >
    メール通知設定
  </MText>
</template>
