<script setup>
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';

const { fetchNewProjects, loading, pagination, projects } = useFetchProjects();
</script>

<template>
  <ViewBase
    v-model:pagination="pagination"
    class="new-view"
    :loading
    :projects
    @fetch="fetchNewProjects({ page: pagination.page })"
  />
</template>
