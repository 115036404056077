import Cookies from 'js-cookie';
import friendInviteApi from '@/modules/api/v2/friendinvite';

const postFriendInviteCode = onSuccess => {
  const code = Cookies.get('friendInviteCode');

  if (code === undefined) {
    return;
  }

  friendInviteApi
    .postFriendInvite(code)
    .then(result => {
      Cookies.remove('friendInviteCode');

      if (onSuccess !== null) {
        onSuccess(result);
      }
    })
    .catch(e => {
      // 一部エラーの時のみCookieの削除を行わない
      if ([401, 403, 500].includes(e.response?.status)) {
        return;
      }

      Cookies.remove('friendInviteCode');
    });
};

export default {
  postFriendInviteCode,
};
