<script setup>
import { watch } from 'vue';
import {
  MButton,
  MDialog,
  MDivider,
  MText,
  useDisplay,
} from '@ca-crowdfunding/makuake-ui-n';
import ScrollContainer from '@/components/common/ScrollContainer';
import MyTagButton from '@/components/favorite/MyTagButton';
import useTag from '@/composables/common/tag';
import useMyTag from '@/composables/favorite/myTag';
import { APPROX_TAG_COUNT, MY_TAG_LIMIT } from '@/consts/tag';

const emit = defineEmits(['fetch']);
const open = defineModel({ default: false, type: Boolean });

const { fetchTags, popularTags, tags } = useTag();
const { myTagsOnServer } = useMyTag();
const { xs } = useDisplay();

watch(open, newValue => {
  if (newValue && !tags.value.length) fetchTags();
  else emit('fetch');
});
</script>

<template>
  <MDialog
    v-model="open"
    class="flex flex-col p-0"
    :full-screen="xs"
    max-height="calc(100dvh - 3rem)"
    max-width="920"
  >
    <div class="flex items-center justify-between py-3 px-6 sm:py-6">
      <MText>{{ myTagsOnServer.length }}/{{ MY_TAG_LIMIT }}</MText>
      <MButton
        class="-m-3"
        icon="close"
        variant="plain"
        @click="open = false"
      />
    </div>
    <MDivider color="tertiary" />
    <div class="dialog-body p-6">
      <MText class="mb-8" size="x-large" sm="2x-large" weight="bold" tag="h3"
        >Myタグ登録</MText
      >
      <MText class="mb-4" tag="h4">よく登録されているタグ</MText>
      <ScrollContainer class="gap-2 py-2 -mt-2 mb-6" tag="ul">
        <li v-for="tag in popularTags" :key="tag.id" class="shrink-0">
          <MyTagButton :name="tag.name" :tag-id="tag.id" />
        </li>
      </ScrollContainer>
      <ul class="tags-all grid gap-2">
        <template v-if="!tags.length">
          <li
            v-for="key in APPROX_TAG_COUNT"
            :key
            class="bg-black/8 rounded h-10 w-full"
          />
        </template>
        <template v-else>
          <li v-for="tag in tags" :key="tag.id" class="flex">
            <MyTagButton class="grow" :name="tag.name" :tag-id="tag.id" />
          </li>
        </template>
      </ul>
    </div>
  </MDialog>
</template>

<style scoped>
.dialog-body {
  overflow-y: auto;
  scrollbar-color: rgba(34 34 34 / 40%) transparent;
}

.tags-all {
  grid-template-columns: repeat(auto-fill, minmax(min(10rem, 48.5%), 1fr));
}
</style>
