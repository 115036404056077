<script setup>
import { onMounted } from 'vue';
import { MSkeleton, MText } from '@ca-crowdfunding/makuake-ui-n';
import NavigationItem from '@/components/navigation/NavigationItem';
import useTag from '@/composables/common/tag';
import { ROUTES } from '@/consts/navigation';
import { APPROX_TAG_COUNT } from '@/consts/tag';

const { fetchTags, isLoading, tags } = useTag();

onMounted(() => tags.value.length || fetchTags());
</script>

<template>
  <ul v-if="tags.length || isLoading" class="tags-all grid gap-2">
    <template v-if="isLoading">
      <li v-for="key in APPROX_TAG_COUNT" :key class="flex items-center h-8">
        <MSkeleton
          class="w-full"
          size="small"
          :lines="Math.floor(Math.random() * 5 + 3) / 10"
        />
      </li>
    </template>
    <template v-else>
      <li v-for="tag in tags" :key="tag.id">
        <NavigationItem
          :to="{ name: ROUTES.TAG.name, params: { tagId: tag.id } }"
          :tracking-id="`tag_${tag.id}`"
        >
          <MText size="small" max-lines="1"># {{ tag.name }}</MText>
        </NavigationItem>
      </li>
    </template>
  </ul>
</template>

<style scoped>
ul {
  grid-template-columns: repeat(auto-fill, minmax(min(12rem, 48.5%), 1fr));
}
</style>
