<script setup>
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import { SECTION_TITLE } from '@/consts/recommend';

const { fetchRecommendedProjects, loading, projects, userId } =
  useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(fetchRecommendedProjects);
</script>

<template>
  <ProjectSectionBase
    class="recommended-section"
    label="recommend"
    :loading
    :projects
    :title="SECTION_TITLE"
  >
    <input id="recommend_user_id" type="hidden" :value="userId" />
  </ProjectSectionBase>
</template>
