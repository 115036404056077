<script setup>
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import loggedInStatus from '@/modules/isLoggedinStatus';

const userLoggedIn = loggedInStatus.isUserLoggedin();
const { fetchRemindedProjects, loading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(fetchRemindedProjects);
</script>

<template>
  <ProjectSectionBase
    v-if="userLoggedIn"
    label="project-end"
    :loading
    :projects
    title="応援購入忘れはありませんか"
  />
</template>
