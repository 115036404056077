<script setup>
import { inject, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { MText } from '@ca-crowdfunding/makuake-ui-n';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';

const route = useRoute();
const heading = inject('heading');

const { fetchProjectsByKeyword, loading, pagination, projects } =
  useFetchProjects();

onMounted(() => {
  heading.value = `"${route.query.keyword ?? ''}" の検索結果`;
});

watch(
  () => pagination.value.total,
  total => {
    heading.value += ` ${total} 件`;
  },
);
</script>

<template>
  <ViewBase
    v-model:pagination="pagination"
    class="projects-view"
    :loading
    :projects
    @fetch="
      fetchProjectsByKeyword($route.query.keyword, { page: pagination.page })
    "
  >
    <template #empty>
      <MText md="large" tag="p"
        >条件に一致するプロジェクトは見つかりませんでした</MText
      >
    </template>
  </ViewBase>
</template>
