<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { MText, useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import ProjectItem from '@/components/common/ProjectItem';
import useIntersection from '@/composables/common/intersection';
import { PER_PAGE } from '@/consts/discover';

const props = defineProps({
  comingSoon: { type: Boolean, default: false },
  itemWidth: { type: String },
  loading: { type: Boolean, default: false },
  perPage: { type: Number, default: PER_PAGE },
  projects: { type: Array, required: true },
});

const emit = defineEmits(['fetch']);

const pagination = defineModel('pagination', { type: Object, required: true });

const hasMore = ref(true);
const target = ref(null);
const { setupIntersection } = useIntersection();

setupIntersection(
  async () => {
    pagination.value.page += 1;
    emit('fetch');
  },
  { infinity: true, target },
);

onMounted(() => {
  pagination.value.page = 1;
  emit('fetch');
});

watch(pagination, v => {
  hasMore.value = v.total > v.per_page * v.page;
});

const { sm, xs } = useDisplay();

const width = computed(() => {
  if (props.itemWidth) return props.itemWidth;
  if (xs.value) return '160px';
  if (sm.value) return '224px';
  return '280px';
});
</script>

<template>
  <ul
    v-if="projects?.length || loading"
    class="project-list grid gap-x-2 gap-y-8 -mx-3 sm:gap-x-6 sm:gap-y-10 sm:m-0"
  >
    <slot name="projects" :projects>
      <li v-for="(project, key) in projects" :key>
        <slot name="project" :project>
          <ProjectItem
            :coming-soon="project.is_coming_soon"
            :index="key"
            :project
          >
            <template #[`thumbnail.append`]>
              <slot name="project.thumbnail.append" :project />
            </template>
            <template #subtext>
              <slot name="project.subtext" :project />
            </template>
            <template #status>
              <slot name="project.status" :project />
            </template>
          </ProjectItem>
        </slot>
      </li>
    </slot>
    <template v-if="loading">
      <slot name="loaders" :amount="perPage">
        <li v-for="key in perPage" :key>
          <slot name="loading" :order="key">
            <ProjectItem :coming-soon loading>
              <template #[`loader.subtext`]>
                <slot name="project.loader.subtext" />
              </template>
              <template #[`loader.status`]>
                <slot name="project.loader.status" />
              </template>
            </ProjectItem>
          </slot>
        </li>
      </slot>
    </template>
    <li v-show="hasMore" ref="target"></li>
  </ul>
  <div v-else>
    <slot name="empty">
      <MText sm="large" tag="p">プロジェクトはありません</MText>
    </slot>
  </div>
</template>

<style scoped>
.project-list {
  grid-template-columns: repeat(auto-fill, minmax(v-bind(width), 1fr));
}
</style>
