<script setup>
import { register } from 'swiper/element/bundle';
import { computed, nextTick, onBeforeMount, onUpdated, ref } from 'vue';
import { useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import BannerItem from '@/components/home/BannerItem';
import HeroNavigation from '@/components/home/HeroNavigation';
import api from '@/modules/api/v2/heroes';

import 'swiper/element/css/mousewheel';

// Swiper のカスタム要素を登録
register();

const id = 'banner-container';
const prevClass = 'banner-prev';
const nextClass = 'banner-next';

const swiperParams = {
  a11y: {
    // ナビゲーションのaria-controls属性用に必要
    id,
  },
  breakpoints: {
    640: {
      spaceBetween: 24,
    },
    960: {
      centeredSlides: false,
      loop: false,
      spaceBetween: 24,
    },
  },
  centeredSlides: true,
  loop: true,
  mousewheel: {
    // マウスホイール操作は水平方向のみ有効にする
    forceToAxis: true,
  },
  navigation: {
    nextEl: `.${nextClass}`,
    prevEl: `.${prevClass}`,
  },
  slidesPerView: 'auto',
  spaceBetween: 12,
  speed: 400,
  threshold: 16,
};

const banners = ref([]);

const fetchBanners = async () => {
  const { data } = await api.fetchBanners();
  if (data?.heroes?.length) banners.value = data.heroes;
};

onBeforeMount(fetchBanners);

const initSwiper = () => {
  const swiperEl = document.getElementById(id);
  if (swiperEl) {
    // 取得した件数に応じてループを無効化する
    if (banners.value.length) {
      const width = Math.max(1, 320 * (banners.value.length - 2));
      const noLoop = {
        centeredSlides: false,
        loop: false,
        spaceBetween: width < 640 ? 12 : 24,
      };

      if (width <= 1920) {
        swiperParams.breakpoints[width] = {
          ...swiperParams.breakpoints[width],
          ...noLoop,
        };
      }

      if (width <= 640) {
        swiperParams.breakpoints[640] = {
          ...swiperParams.breakpoints[640],
          ...noLoop,
        };
      }
    }
    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();
  }
};

const { smAndUp } = useDisplay();
const width = computed(() => `${smAndUp.value ? 320 : 240}px`);

onUpdated(() => {
  nextTick(() => {
    initSwiper();
  });
});
</script>

<template>
  <section
    v-if="banners.length"
    class="banner-area bg-tertiary overflow-hidden -mx-6 py-4 md:ml-0"
  >
    <div class="relative px-6">
      <swiper-container :id init="false">
        <swiper-slide v-for="(banner, index) in banners" :key="index">
          <BannerItem :banner :index />
        </swiper-slide>
      </swiper-container>
      <HeroNavigation :next-class :prev-class />
    </div>
  </section>
</template>

<style lang="scss" scoped>
::part(container) {
  overflow: visible;
}

swiper-slide {
  max-width: calc(100% - 2rem);
  width: v-bind(width);
}
</style>
