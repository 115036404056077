<script setup>
import AppLink from '@/components/common/AppLink';

defineProps({
  to: [String, Object],
  trackingId: String,
});

const staticClass = 'flex items-center justify-between w-full h-8 p-2 rounded';
const hoverClass = 'hover:bg-secondary hover:no-underline';
</script>

<template>
  <div v-if="!to" :class="staticClass">
    <slot />
  </div>
  <AppLink
    v-else
    :class="[staticClass, hoverClass]"
    active-class="bg-secondary"
    :to
    :tracking-id
  >
    <slot />
  </AppLink>
</template>
