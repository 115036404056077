import useMeta from '@/composables/common/meta';
import { NOT_FOUND_TITLE, SUFFIX } from './meta';

const { generateDescription } = useMeta();

const obj = {
  ALL: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: 'すべて',
      title: `すべての${SUFFIX}`,
    },
    path: 'all',
  },
  ALL_TIME_RANKING: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: '歴代ランキング',
      title: '歴代ランキング',
    },
    path: 'most-funded',
  },
  CATEGORY: {
    path: 'categories/:categorySlug',
  },
  COMING_SOON: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: 'もうすぐ開始',
      title: `もうすぐ開始する${SUFFIX}`,
    },
    path: 'coming-soon',
  },
  DISCOVER: {
    path: '/discover',
  },
  ENDING_SOON: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: 'もうすぐ終了',
      title: `もうすぐ終了する${SUFFIX}`,
    },
    path: 'ending-soon',
  },
  FAVORITE: {
    path: '/favorite',
  },
  GOVERNMENT: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: 'ふるさと納税型',
      title: `ふるさと納税型の${SUFFIX}`,
    },
    path: 'government',
  },
  HISTORY: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: '閲覧履歴',
      title: '閲覧履歴',
    },
    path: 'watched',
  },
  HOME: {
    meta: {
      heading: 'ホーム',
    },
    path: '/',
  },
  LIKED: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: '気になる',
      title: `気になる${SUFFIX}`,
    },
    path: 'project',
  },
  LOCATION: {
    path: 'locations/:locationIds([\\d,]+)',
  },
  LOCATIONS_ALL: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: '地域',
      title: '地域一覧',
    },
    path: 'locations',
  },
  MY_TAG: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: 'Myタグ',
      title: 'Myタグ',
    },
    path: 'tag',
  },
  NEW: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: '新着',
      title: `新着${SUFFIX}`,
    },
    path: 'new',
  },
  NOT_FOUND: {
    meta: {
      hideBreadcrumbs: true,
      heading: NOT_FOUND_TITLE,
      title: NOT_FOUND_TITLE,
    },
    path: ':pathMatch(.*)*',
  },
  PICKUP: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: '今日のピックアップ',
      title: `今日のピックアップ${SUFFIX}`,
    },
    path: 'pickup',
  },
  PROJECTS: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      hideBreadcrumbs: true,
      title: 'プロジェクトを探す',
    },
    path: 'projects',
  },
  RANKING: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: '今日のランキング',
      title: '今日のランキング',
    },
    path: 'ranking',
  },
  SELECTED_OWNERS: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: '推奨実行者',
      title: `推奨実行者の${SUFFIX}`,
    },
    path: 'selected-creators',
  },
  TAG: {
    path: 'tags/:tagId(\\d+)',
  },
  TAGS_ALL: {
    meta: {
      description() {
        return generateDescription(this.title);
      },
      heading: 'すべてのタグ',
      title: 'すべてのタグ',
    },
    path: 'tags',
  },
};

Object.keys(obj).forEach(key => {
  obj[key].name = key.toLowerCase();
});

export const ROUTES = Object.freeze(obj);
