<script setup>
import { computed } from 'vue';
import { RouterLink } from 'vue-router';
import useLink from '@/composables/link';
import useGaEvent from '@/composables/common/gaEvent';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  ...RouterLink.props,
  index: Number,
  trackingId: String,
});

const { resolveLink } = useLink();
const { sendClickEvent } = useGaEvent();

const target = computed(() => resolveLink(props.to).target);
const isRouterLink = computed(() => typeof props.to === 'object');

const onClick = () => {
  if (!props.trackingId) return;
  sendClickEvent(props.trackingId, props.index);
};
</script>

<template>
  <RouterLink
    v-if="isRouterLink"
    v-slot="{ isActive, href, navigate }"
    v-bind="$props"
    custom
  >
    <a
      v-bind="$attrs"
      :href="href"
      :class="isActive && activeClass"
      @click="onClick"
      @click.capture="navigate"
    >
      <slot />
    </a>
  </RouterLink>
  <a v-else v-bind="$attrs" :href="to" :target @click="onClick">
    <slot />
  </a>
</template>

<style scoped>
.hover\:no-underline:hover {
  text-decoration-line: none;
}

a:focus-visible {
  outline: auto;
  outline-color: #4ab3df;
}
</style>
