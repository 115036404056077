import client from '@/modules/api/createClient';

const fetchProjectsEnd = () => {
  const url = '/projects?type=maybe-missing';

  return client.phpApiClient
    .get(url)
    .then(response => response)
    .catch(error => error);
};

export default {
  fetchProjectsEnd,
};
