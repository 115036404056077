import { createClient } from '@/modules/api/createClient';

const fetchAll = ({ withReturns = true, withUser = true } = {}) =>
  createClient().get(
    `/v2/heroes?with_returns=${withReturns}&with_user=${withUser}`,
  );

const fetchBanners = () => createClient().get('/v2/heroes?type=banner');

const fetchProjects = ({ withReturns = true, withUser = true } = {}) =>
  createClient().get(
    `/v2/heroes?type=project&with_returns=${withReturns}&with_user=${withUser}`,
  );

export default {
  fetchAll,
  fetchBanners,
  fetchProjects,
};
