export const LOCATIONS = Object.freeze({
  すべて: {
    日本: {
      東日本: {
        北海道: { 北海道: 1 },
        東北: { 青森: 2, 岩手: 3, 宮城: 4, 秋田: 5, 山形: 6, 福島: 7 },
        関東: {
          茨城: 8,
          栃木: 9,
          群馬: 10,
          埼玉: 11,
          千葉: 12,
          東京: 13,
          神奈川: 14,
        },
        中部: {
          新潟: 15,
          富山: 16,
          石川: 17,
          福井: 18,
          山梨: 19,
          長野: 20,
          岐阜: 21,
          静岡: 22,
          愛知: 23,
        },
      },
      西日本: {
        近畿: {
          三重: 24,
          滋賀: 25,
          京都: 26,
          大阪: 27,
          兵庫: 28,
          奈良: 29,
          和歌山: 30,
        },
        中国: { 鳥取: 31, 島根: 32, 岡山: 33, 広島: 34, 山口: 35 },
        四国: { 徳島: 36, 香川: 37, 愛媛: 38, 高知: 39 },
        '九州・沖縄': {
          福岡: 40,
          佐賀: 41,
          長崎: 42,
          熊本: 43,
          大分: 44,
          宮崎: 45,
          鹿児島: 46,
          沖縄: 47,
        },
      },
    },
    海外: { 海外: 48 },
  },
});
