<script setup>
import { ref, onMounted, watch } from 'vue';
import { MSkeleton } from '@ca-crowdfunding/makuake-ui-n';
import SectionTitle from '@/components/discover/SectionTitle';
import ViewBase from '@/components/discover/ViewBase';
import MailSettingsLink from '@/components/favorite/MailSettingsLink';
import MyTagForm from '@/components/favorite/MyTagForm';
import useFetchProjects from '@/composables/common/fetchProjects';
import useMyTag from '@/composables/favorite/myTag';
import loggedInStatus from '@/modules/isLoggedinStatus';
import store from '@/store';

const userLoggedIn = loggedInStatus.isUserLoggedin();
const { initialize, isLoaded, myTags } = useMyTag();
const { fetchMyTagProjects, loading, pagination, projects } =
  useFetchProjects();

const loggedIn = ref(userLoggedIn);

watch(
  () => store.state.isLogin,
  newValue => {
    loggedIn.value = newValue;
  },
);

onMounted(() => {
  if (!isLoaded.value) {
    initialize();
  }
});
</script>

<template>
  <MyTagForm class="mb-12 sm:mb-10" :logged-in @fetch="fetchMyTagProjects" />
  <template v-if="loggedIn && (!isLoaded || myTags.length)">
    <div class="flex flex-wrap items-center justify-between mb-6">
      <template v-if="!isLoaded">
        <MSkeleton
          class="w-32 sm:w-40"
          lines="1"
          size="x-large"
          sm="3x-large"
        />
        <MSkeleton class="w-24 sm:w-28" lines="1" size="small" sm="medium" />
      </template>
      <template v-else>
        <SectionTitle tag="h3" title="Myタグの新着" />
        <MailSettingsLink />
      </template>
    </div>
    <ViewBase
      v-model:pagination="pagination"
      class="my-tag-view"
      :loading
      :projects
      @fetch="fetchMyTagProjects({ page: pagination.page })"
    />
  </template>
</template>
