<script setup>
import { computed } from 'vue';
import { MButton } from '@ca-crowdfunding/makuake-ui-n';
import useGaEvent from '@/composables/common/gaEvent';
import useMyTag from '@/composables/favorite/myTag';
import { MY_TAG_LIMIT } from '@/consts/tag';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  tagId: {
    type: [String, Number],
    required: true,
  },
});

const { sendClickEvent } = useGaEvent();
const { handleClick, myTags } = useMyTag();

const tagId = computed(() => Number(props.tagId));
const isRegistered = computed(() =>
  myTags.value.some(tag => tag.id === tagId.value),
);

const onClick = () => {
  const prefix = isRegistered.value ? 'unregister' : 'register';
  sendClickEvent(`${prefix}_tag_${tagId.value}`);
  handleClick(tagId.value, props.name);
};
</script>

<template>
  <MButton
    :prepend-icon="isRegistered ? 'remove' : 'add'"
    class="justify-start"
    :disabled="myTags.length >= MY_TAG_LIMIT && !isRegistered"
    size="small"
    :variant="isRegistered ? 'flat' : 'tonal'"
    @click="onClick"
  >
    {{ name }}
  </MButton>
</template>

<style scoped>
/* TODO: MButton が twMarge に対応後削除 */
.justify-start {
  justify-content: start;
}
</style>
