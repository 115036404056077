<script setup>
import { onBeforeMount } from 'vue';
import { MCol, MRow } from '@ca-crowdfunding/makuake-ui-n';
import FriendInviteTicketAcquireModal from '@/components/friendinvite/FriendInviteTicketAcquireModal';
import ActivityReportSection from '@/components/home/ActivityReportSection';
import ApplicationSection from '@/components/home/ApplicationSection';
import BannerArea from '@/components/home/BannerArea';
import BlogSection from '@/components/home/BlogSection';
import CatchCopy from '@/components/home/CatchCopy';
import CategorySection from '@/components/home/CategorySection';
import ComingSoonSection from '@/components/home/ComingSoonSection';
import EventSection from '@/components/home/EventSection';
import HeroArea from '@/components/home/HeroArea';
import HistorySection from '@/components/home/HistorySection';
import IntroductionSection from '@/components/home/IntroductionSection';
import NewArrivalsSection from '@/components/home/NewArrivalsSection';
import PickupSection from '@/components/home/PickupSection';
import PopularSection from '@/components/home/PopularSection';
import RankingSection from '@/components/home/RankingSection';
import RecommendedListSection from '@/components/home/RecommendedListSection';
import RecommendedSection from '@/components/home/RecommendedSection';
import RemindedSection from '@/components/home/RemindedSection';
import ServiceSection from '@/components/home/ServiceSection';
import TagSection from '@/components/home/TagSection';
import TaggedSection from '@/components/home/TaggedSection';
import useDevice from '@/composables/common/device';
import useABTestPX2671 from '@/composables/home/abTestPX2671';
import useABTestPX2751 from '@/composables/home/abTestPX2751';
import { TAGS } from '@/consts/home';

const { app, loadDevice, pc, sp } = useDevice();

// TODO:ABテストが終わったら削除
// https://makuake.atlassian.net/browse/PX-2671
const { isPX2671NonDefaultDisplay, px2671DisplayNumber, px2671DisplayRows } =
  useABTestPX2671(pc);
// TODO:ABテストが終わったら削除
// https://makuake.atlassian.net/browse/PX-2751
const { isPX2751NonDefaultDisplay, px2751DisplayNumber } = useABTestPX2751(sp);

onBeforeMount(async () => {
  await loadDevice();
  if (app.value) return;

  const { braze } = window;

  if (braze) {
    braze.logCustomEvent('pageview_top', { timestamp: new Date() });
  }
});
</script>

<template>
  <div class="flex flex-col gap-10 md:mt-2">
    <CatchCopy />
    <HeroArea />
    <BannerArea />
    <CategorySection />
    <HistorySection />
    <RemindedSection />
    <!-- TODO: ABテスト終了後に不要箇所削除
     https://makuake.atlassian.net/browse/PX-2671
     PCテストのBCパターン -->
    <RecommendedListSection
      v-if="isPX2671NonDefaultDisplay"
      :display-number="px2671DisplayNumber"
      :display-rows="px2671DisplayRows"
    />
    <!-- TODO: ABテスト終了後に不要箇所削除
     https://makuake.atlassian.net/browse/PX-2751
     SPテストのBCDパターン -->
    <RecommendedListSection
      v-else-if="isPX2751NonDefaultDisplay"
      :display-number="px2751DisplayNumber"
    />
    <!-- Aパターン -->
    <RecommendedSection v-else />
    <PickupSection />
    <RankingSection />
    <NewArrivalsSection />
    <ComingSoonSection />
    <PopularSection />
    <TaggedSection :tag="TAGS.MADE_IN_JAPAN" />
    <TaggedSection :tag="TAGS.RESTAURANT" />
    <TaggedSection :tag="TAGS.UTILITY_GOODS" />
    <TaggedSection :tag="TAGS.GADGET" />
    <TaggedSection :tag="TAGS.FASHION" />
    <TaggedSection :tag="TAGS.OUTDOOR" />
    <ApplicationSection />
    <TagSection />
    <MRow class="max-w-screen-lg" gap="10">
      <MCol cols="12" md="6"><ActivityReportSection /></MCol>
      <MCol cols="12" md="6"><BlogSection /></MCol>
    </MRow>
    <EventSection />
    <IntroductionSection />
    <ServiceSection />
  </div>
  <FriendInviteTicketAcquireModal />
</template>
