<script setup>
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import { PROJECT_DISPLAY_NUMBER } from '@/consts/home';

const { fetchComingSoonProjects, loading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(() =>
  fetchComingSoonProjects({
    perPage: PROJECT_DISPLAY_NUMBER * 5,
    random: PROJECT_DISPLAY_NUMBER,
  }),
);
</script>

<template>
  <ProjectSectionBase
    coming-soon
    href="/discover/coming-soon"
    label="coming_soon"
    :loading
    :projects
    title="もうすぐ開始"
  />
</template>
