import { createClient } from '@/modules/api/createClient';
import { buildUrl } from '@/modules/api/buildUrl';

/** クエリストリングパラメータのtag_groupとしてエンドポイントで処理可能な値 */
const availableTagGroups = ['gadget', 'fashion', 'gourmet', 'outdoors'];

const hasValidTagGroup = params => {
  if (!('tag_group' in params)) return true;

  const tg = params.tag_group;
  return typeof tg === 'string' && availableTagGroups.includes(tg);
};

const getValidatedParams = params => {
  const copy = { ...params };
  if (!hasValidTagGroup(copy)) delete copy.tag_group;

  return copy;
};

const fetchRanking = params => {
  // 上書き可
  const defaultParams = { limit: 10 };
  // 上書き不可
  const forceParams = {};

  // 上書き可
  const url = buildUrl('/v2/rankings', {
    ...defaultParams,
    ...getValidatedParams(params),
    ...forceParams,
  });
  return createClient().get(url);
};

export default {
  fetchRanking,
};
