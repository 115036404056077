import GOOD_ACTION from '@/consts/goodAction';
import client from '@/modules/api/createClient';
import security from '@/modules/api/php/security';
import Cookie from 'js-cookie';

/**
 * @typedef {{
 * goods: string;
 * liked_project_ids: number[];
 * }} FetchGoodsResponse
 * `goods`はプロジェクトID一覧がカンマ区切りされた文字列で、`liked_project_ids`はプロジェクトID一覧
 */

/**
 * ログインユーザーが気になるしているプロジェクトのID一覧を取得する
 *
 * @param {{
 * quick: 0 | 1;
 * }} [opt] オプション
 * - quick: `0`はデータの完全性重視の場合に、`1`は不完全だがパフォーマンス重視の場合に使用する（デフォルトは`1`）
 * @returns {Promise<import('axios').AxiosResponse<FetchGoodsResponse | ''>>}
 * 未ログイン時の`AxiosResponse.data`は空文字になる（ステータス204時のaxiosの仕様な模様）。\
 * ログイン時の`AxiosResponse.data`は`FetchGoodsResponse`型のオブジェクトになる。
 */
const fetchGoods = ({ quick } = { quick: 1 }) =>
  client.phpApiClient.get('good/select', { params: { quick } });

/**
 * 気になる登録をAPIにリクエストする
 *
 * @param {number|string} projectId
 */
const registerGood = async projectId =>
  security.securePost(
    'good/regist',
    {
      project_id: projectId,
      action: GOOD_ACTION.REGISTER,
    },
    {
      // APIクライアントで400も有効にされているので上書きする
      validateStatus(/** @type {number} */ status) {
        return status >= 200 && status < 300;
      },
    },
  );

/**
 * 気になる登録解除をAPIにリクエストする
 *
 * @param {number|string} projectId
 */
const unregisterGood = async projectId =>
  security.securePost(
    'good/regist',
    {
      project_id: projectId,
      action: GOOD_ACTION.UNREGISTER,
    },
    {
      // APIクライアントで400も有効にされているので上書きする
      validateStatus(/** @type {number} */ status) {
        return status >= 200 && status < 300;
      },
    },
  );

const COOKIE_IS_GOOD = 'is_good';

/**
 * 気になるしているプロジェクトのID一覧をCookieから取得する
 *
 * @returns {string[]}
 */
const getIds = () => {
  const cookieValue = Cookie.get(COOKIE_IS_GOOD);
  return cookieValue ? cookieValue.split(',').filter(e => !!e) : [];
};

/**
 * 気になるしているプロジェクトのID一覧をCookieに保存する
 *
 * @param {string[]} projectIds
 */
const setCookie = projectIds => {
  const date = new Date();
  // 有効期限を5年後に設定
  const expirationDate = date.setFullYear(date.getFullYear() + 5);
  Cookie.set(COOKIE_IS_GOOD, projectIds.join(','), {
    expires: expirationDate,
    path: '/',
    secure: true,
  });
};

/**
 * 制限超過を示すカスタムエラークラス
 *
 * @class LimitExceededError
 * @extends {Error}
 */
class LimitExceededError extends Error {
  /**
   * LimitExceededErrorのインスタンスを作成する
   *
   * @param {string} message エラーメッセージ
   */
  constructor(message) {
    super(message);
    this.name = 'LimitExceededError';
  }
}

/**
 * 気になるしているプロジェクトのID一覧にプロジェクトIDを追加してCookieに保存する
 *
 * @param {number|string} projectId
 */
const addIdToCookie = projectId => {
  const projectIds = getIds();
  if (projectIds.length >= 10 && !projectIds.includes(String(projectId))) {
    throw new LimitExceededError('最大10件まで登録できます');
  }

  if (!projectIds.includes(String(projectId))) {
    projectIds.push(projectId);
    setCookie(projectIds);
  }
};

/**
 * 気になるしているプロジェクトのID一覧から一致するプロジェクトIDを削除してCookieに保存する
 * @param {number|string} projectId
 */
const removeIdFromCookie = projectId => {
  const projectIds = getIds();
  const modifiedIds = projectIds.filter(e => e !== String(projectId));
  setCookie(modifiedIds);
};

/**
 * 気になるしているプロジェクトのID一覧に該当プロジェクトIDが含まれているか判定する
 *
 * @param {number|string} projectId
 */
const isAlreadyInCookie = projectId => {
  const projectIds = getIds();
  return projectIds.some(e => e === String(projectId));
};

export default {
  addIdToCookie,
  fetchGoods,
  getIds,
  isAlreadyInCookie,
  LimitExceededError,
  registerGood,
  removeIdFromCookie,
  unregisterGood,
};
