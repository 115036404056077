import { ref } from 'vue';

const snackbar = ref(false);
const props = ref({});
const message = ref('');
const action = ref(() => {});

export default function useSnackbar() {
  const defaultOptions = {
    action: () => {},
    actionLabel: '',
    message: '',
  };

  const showError = (options = {}) => {
    snackbar.value = true;
    props.value.color = 'danger';

    const mergedOptions = { ...defaultOptions, ...options };

    Object.keys(mergedOptions).forEach(key => {
      if (key === 'message') message.value = mergedOptions[key];
      else if (key === 'action') action.value = mergedOptions[key];
      else props.value[key] = mergedOptions[key];
    });
  };

  return {
    action,
    message,
    props,
    snackbar,
    showError,
  };
}
