import { createClient } from '@/modules/api/createClient';
import { buildUrl } from '@/modules/api/buildUrl';

const fetchAll = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = {};

  const url = buildUrl('/v2/contents', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

export default {
  fetchAll,
};
