<script setup>
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import ProjectItem from '@/components/discover/ProjectItemForRanking';
import RankingTags from '@/components/discover/RankingTags';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import { RANKING_DISPLAY_NUMBER } from '@/consts/discover';

const { fetchRankedProjects, loading, pagination, projects } =
  useFetchProjects();
const { xs } = useDisplay();
const route = useRoute();

const group = computed(() => route.query.group);

watch(
  group,
  () => {
    projects.value = [];
    fetchRankedProjects({
      perPage: RANKING_DISPLAY_NUMBER,
      tagGroup: group.value,
    });
  },
  { immediate: true },
);
</script>

<template>
  <RankingTags :group />
  <ViewBase
    v-model:pagination="pagination"
    class="ranking-view"
    :loading
    :per-page="RANKING_DISPLAY_NUMBER"
    :projects
  >
    <template #loaders="{ amount }">
      <li
        v-for="key in amount"
        :key
        :class="{ 'col-span-full': xs && key < 10 }"
      >
        <ProjectItem loading :rank="key + 1" />
      </li>
    </template>
    <template #projects="{ projects: list }">
      <li
        v-for="(project, key) in list"
        :key
        :class="{ 'col-span-full': xs && key < 10 }"
      >
        <ProjectItem :project :rank="project.rank" />
      </li>
    </template>
  </ViewBase>
</template>

<style scoped>
.col-span-full {
  grid-column: 1 / -1;
}
</style>
