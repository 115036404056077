<script setup>
import RankLabel from '@/components/common/RankLabel';
import ProjectFilter from '@/components/discover/ProjectFilter';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import { NO_SORT_OPTIONS } from '@/consts/discover';

const { fetchMostFundedProjects, filter, loading, pagination, projects } =
  useFetchProjects();
</script>

<template>
  <ProjectFilter
    v-model:filter="filter"
    :is-in-store="false"
    :is-ongoing="false"
    :loading
    :sort-options="NO_SORT_OPTIONS"
    @fetch="fetchMostFundedProjects"
  />
  <ViewBase
    v-model:pagination="pagination"
    class="all-time-ranking-view"
    :loading
    :projects
    @fetch="fetchMostFundedProjects({ page: pagination.page })"
  >
    <template #[`project.thumbnail.append`]="{ project }">
      <RankLabel v-if="project.rank <= 100" :rank="project.rank" />
    </template>
  </ViewBase>
</template>
