import { computed } from 'vue';
import env from '@/modules/env';
import { isProductionDefaultTrue } from '@/modules/nodeEnv';

export default function useEnvDefinition() {
  const isProduction = computed(() => isProductionDefaultTrue()).value;

  return {
    env,
    isProduction,
  };
}
