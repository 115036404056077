import Cookie from 'js-cookie';

export default function useCookie() {
  /**
   * @param {string} key
   * @returns {string|undefined}
   */
  const getValueFromCookie = key => {
    if (!key) return undefined;

    return Cookie.get(key);
  };

  /**
   * @param {string} key
   * @param {string} value
   * @param {string|number} expires 有効期限（日数）
   */
  const setValueToCookie = (key, value, expires = 30) => {
    if (!key || !value) {
      throw new Error('Invalid key or value');
    }
    if (Number.isNaN(Number(expires)) || Number(expires) <= 0) {
      throw new Error('Invalid expires');
    }

    Cookie.set(key, value, { expires, secure: true, sameSite: 'strict' });
  };

  return {
    getValueFromCookie,
    setValueToCookie,
  };
}
