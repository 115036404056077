import useCookie from '@/composables/common/cookie';
import {
  FIRST_VISIT_DAY_KEY,
  LAST_VISIT_DAY_KEY,
  VARIANT_KEY,
  VISIT_COUNT_KEY,
  WATCHED_KEY,
} from '@/consts/cookie';

/**
 * @typedef {Object} Variant
 * @property {string} variant パターン
 * @property {number} weight 重み付け
 */

/**
 * @param {string} testName
 * @param {Variant[]} variants
 * @returns {Object}
 */
export default function useFirstVisitForABTest(testName, variants) {
  const testNameKey = `${testName.toLowerCase()}_ab`;

  const { getValueFromCookie, setValueToCookie } = useCookie();

  /**
   * 初回訪問データをGTM/GA4に送信する
   * @param {string|undefined} variant ABテストのパターン
   */
  const sendABTestFirstVisitEvent = variant => {
    if (!variant) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ab_visit',
      name: `${testName.toLocaleLowerCase()}_${variant}`,
      count: 0,
    });
  };

  /**
   * ABテストのパターンを重み付けに合わせて新規で割り当てCookieに保存する
   * @returns {string|undefined}
   */
  const assignVariant = () => {
    const cookieValue = getValueFromCookie(WATCHED_KEY);
    const watchedIds = cookieValue ? cookieValue.split(',') : [];

    // 閲覧履歴が5件以下を新規ユーザーとみなす
    // 5件より多い場合は対象外ユーザーなので割り当てはしない
    if (watchedIds.length > 5) return undefined;

    // 重みの合計を計算
    const totalWeight = variants.reduce((sum, v) => sum + v.weight, 0);
    const random = Math.random() * totalWeight;

    // 累積的にランダム値に基づいてパターンを割り当て
    let cumulativeWeight = 0;
    const assigned = variants.find(variant => {
      cumulativeWeight += variant.weight;
      return random < cumulativeWeight;
    });

    if (!assigned) throw new Error('Failed to assign value');

    // Cookieに保存
    const now = new Date(
      new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' }),
    );
    const date = now.getDate();
    const jsonStr = JSON.stringify({
      [VARIANT_KEY]: assigned.variant,
      [FIRST_VISIT_DAY_KEY]: date,
      [LAST_VISIT_DAY_KEY]: date,
      [VISIT_COUNT_KEY]: 0,
    });
    setValueToCookie(testNameKey, jsonStr);

    // イベント送信
    sendABTestFirstVisitEvent(assigned.variant);

    return assigned.variant;
  };

  /**
   * ABテストで割り当てられているパターンを取得する
   * @returns {string|undefined}
   */
  const getSavedVariant = () => {
    const cookieValue = getValueFromCookie(testNameKey);
    // 新規ユーザーの可能性があるのでアサインする
    if (!cookieValue) {
      return assignVariant();
    }

    try {
      const parsedValue = JSON.parse(cookieValue);
      return parsedValue[VARIANT_KEY];
    } catch (e) {
      return undefined;
    }
  };

  return {
    getSavedVariant,
  };
}
