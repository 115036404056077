<script setup>
import { ref } from 'vue';
import { MAvatar, MImg, MText } from '@ca-crowdfunding/makuake-ui-n';
import ArticleItem from '@/components/home/ArticleItem';
import SectionHeader from '@/components/home/SectionHeader';
import useIntersection from '@/composables/common/intersection';
import { ARTICLE_DISPLAY_NUMBER } from '@/consts/home';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';
import api from '@/modules/api/v2/communications';

const reports = ref([]);
const { setupIntersection } = useIntersection();

const fetchActivityReports = async () => {
  const { data } = await api.fetchForTop({
    per_page: ARTICLE_DISPLAY_NUMBER,
    posted: 'owner',
  });
  if (data?.communications?.length) reports.value = data.communications;
};

setupIntersection(fetchActivityReports);
</script>

<template>
  <section class="activity-report-section">
    <SectionHeader
      href="/report"
      label="activity_report"
      title="最新の活動レポート"
    />
    <ul>
      <ArticleItem
        v-for="(report, index) in reports"
        :key="index"
        :content-id="report.id"
        :content-type="SELECT_CONTENT_TYPES.ACTIVITY_REPORT"
        :href="report.url"
        :index
        label="activity_report"
      >
        <template #image>
          <MImg
            v-if="report.project.image_url"
            alt=""
            height-sm="90"
            height="45"
            :src="report.project.image_url"
            width-sm="160"
            width="80"
          />
        </template>
        <div class="flex items-center gap-2 mb-4">
          <MAvatar
            v-if="report.owner?.image_url"
            :alt="report.owner.name"
            class="shrink-0"
            size="16"
            sm="20"
            :src="report.owner.image_url"
          />
          <MText
            class="truncate"
            size="small"
            sm="large"
            tag="h3"
            weight="bold"
          >
            {{ report.title }}
          </MText>
        </div>
        <MText max-lines="3" size="2x-small" sm="small" tag="h4" weight="bold">
          {{ report.project.title }}
        </MText>
      </ArticleItem>
    </ul>
  </section>
</template>
