import Cookies from 'js-cookie';
import { ref, watch } from 'vue';
import useGaEvent from '@/composables/common/gaEvent';
import useSnackbar from '@/composables/common/snackbar';
import { DEFAULT_ERROR_MESSAGE } from '@/consts/error';
import api from '@/modules/api/php/me/tag';
import loggedInStatus from '@/modules/isLoggedinStatus';
import store from '@/store';

const COOKIE_NAME = 'my_tag_discover';
const myTags = ref([]);
const myTagsOnServer = ref([]);
const isLoaded = ref(false);

export default function useMyTag() {
  const { sendAddToTagListEvent } = useGaEvent();
  const { showError } = useSnackbar();

  const fn = async action => {
    if (!store.state.isLogin) return;

    try {
      await action();
    } catch (e) {
      myTags.value = [...myTagsOnServer.value];
      const message = e.response?.data?.errors?.tag_id || DEFAULT_ERROR_MESSAGE;
      showError({ message });
    }
  };

  const fetchMyTags = async () => {
    await fn(async () => {
      const { data } = await api.fetchTags();
      const tags = [
        ...data.tags.map(tag => ({ name: tag.name, id: Number(tag.id) })),
      ];
      myTagsOnServer.value = tags;
      myTags.value = tags;
      isLoaded.value = true;
    });
  };

  const addMyTag = async (tagId, name) => {
    await fn(async () => {
      await api.createTag(tagId);
      // 追加された tag オブジェクトは name を持たない場合がある
      if (myTagsOnServer.value.every(tag => tag.id !== tagId)) {
        myTagsOnServer.value.push({ name, id: tagId });
      }

      // Myタグ登録イベントを送信
      sendAddToTagListEvent(Number(tagId));

      // Braze の CustomAttributes に tagId を追加
      const { braze } = window;
      if (braze) {
        braze.getUser().addToCustomAttributeArray('my_tag', String(tagId));
      }
    });
  };

  const removeMyTag = async tagId => {
    await fn(async () => {
      await api.deleteTag(tagId);
      myTagsOnServer.value = myTagsOnServer.value.filter(
        tag => tag.id !== tagId,
      );

      // Braze の CustomAttributes から tagId を削除
      const { braze } = window;
      if (braze) {
        braze.getUser().removeFromCustomAttributeArray('my_tag', String(tagId));
      }
    });
  };

  const initialize = async tagId => {
    watch(
      () => store.state.isLogin,
      async isLoggedIn => {
        if (isLoggedIn) {
          // CookieにIDがあり他タグページに遷移せず再度訪問した場合に限り新規にMyタグを登録する
          const clickedId = Cookies.get(COOKIE_NAME);
          if (Number(tagId) === Number(clickedId)) await addMyTag(tagId);
          Cookies.remove(COOKIE_NAME);
          fetchMyTags();
        }
      },
      { immediate: true },
    );
  };

  let timeoutId;

  const debounce = (func, delay) => () =>
    new Promise((resolve, reject) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func().then(resolve).catch(reject);
      }, delay);
    });

  const handleClick = async (tagId, name) => {
    const userLoggedIn = loggedInStatus.isUserLoggedin();

    if (!userLoggedIn) {
      // 登録ボタンを押されたタグのIDをCookieに保存し、ログイン後にMyタグ登録処理を行う
      if (myTags.value.some(tag => tag.id === tagId)) {
        Cookies.remove(COOKIE_NAME);
      } else {
        Cookies.set(COOKIE_NAME, tagId, { expires: 1 });
      }
      window.location.href = `/login?ref=${encodeURIComponent(window.location.href)}`;
      return;
    }

    if (myTags.value.some(tag => tag.id === tagId)) {
      myTags.value = myTags.value.filter(tag => tag.id !== tagId);
      const func = debounce(() => removeMyTag(tagId), 300);
      await func();
    } else {
      if (myTags.value.every(tag => tag.id !== tagId)) {
        myTags.value.push({ name, id: tagId });
      }
      const func = debounce(() => addMyTag(tagId, name), 300);
      await func();
    }
  };

  return {
    addMyTag,
    fetchMyTags,
    handleClick,
    initialize,
    isLoaded,
    myTags,
    myTagsOnServer,
    removeMyTag,
  };
}
