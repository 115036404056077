import { createClient } from '@/modules/api/createClient';
import { buildUrl } from '@/modules/api/buildUrl';

const fetchForTop = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = { type: 'unique' };

  const url = buildUrl('/v2/communications', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

export default {
  fetchForTop,
};
