import { getCurrentInstance, onMounted, onUnmounted, toValue } from 'vue';

export default function useIntersection() {
  /**
   * IntersectionObserver をセットアップし要素が表示されたタイミングでコールバックを実行する
   *
   * @param {Function} callback
   * @param {{ target: HTMLElement | import('vue').VueElement | null; infinity: boolean }} options
   */
  const setupIntersection = (callback, options = {}) => {
    const io = new IntersectionObserver(entries => {
      entries.forEach(async entry => {
        if (entry.isIntersecting) {
          await callback();

          if (!options.infinity) {
            io.unobserve(entry.target); // 一度だけ実行するために観察を停止
          }
        }
      });
    });

    onMounted(() => {
      const target = toValue(options.target);
      const el = target || getCurrentInstance()?.vnode?.el;

      if (el instanceof Element) {
        io.observe(el);
        onUnmounted(() => io.disconnect());
      }
    });
  };

  return {
    setupIntersection,
  };
}
