<script setup>
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';

const { fetchPopularProjects, loading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(fetchPopularProjects);
</script>

<template>
  <ProjectSectionBase
    label="return_popular"
    :loading
    :projects
    title="人気のプロジェクト"
  />
</template>
