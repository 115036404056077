import client from '@/modules/api/createClient';
import security from '@/modules/api/php/security';

const fetchTags = () => client.phpApiClient.get(`/me/tags`);

const createTag = tagId =>
  security.securePost(
    `/me/tags/${tagId}/create`,
    {},
    {
      // APIクライアントの設定を上書きする
      validateStatus(/** @type {number} */ status) {
        return (status >= 200 && status < 300) || status === 409;
      },
    },
  );

const deleteTag = tagId =>
  security.securePost(
    `/me/tags/${tagId}/delete`,
    {},
    {
      // APIクライアントの設定を上書きする
      validateStatus(/** @type {number} */ status) {
        return (status >= 200 && status < 300) || status === 409;
      },
    },
  );

export default {
  fetchTags,
  createTag,
  deleteTag,
};
