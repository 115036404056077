<script setup>
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import { HISTORY_DISPLAY_NUMBER } from '@/consts/discover';

const { fetchWatchedProjects, loading, pagination, projects } =
  useFetchProjects();
</script>

<template>
  <ViewBase
    v-model:pagination="pagination"
    class="history-view"
    :loading
    :per-page="HISTORY_DISPLAY_NUMBER"
    :projects
    @fetch="
      fetchWatchedProjects({ perPage: HISTORY_DISPLAY_NUMBER, withUser: true })
    "
  />
</template>
