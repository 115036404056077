<template>
  <div>
    <div
      v-if="didReceiveIAM"
      class="ticket_modal ticket_acquired_modal"
      @click.stop="closeModal"
    >
      <div class="ticket_modal_content">
        <div class="ticket_acquired_modal_header">
          <p v-for="message in messages" :key="message">{{ message }}</p>
        </div>
        <div
          id="ticket-acquired-ticket-modal-body"
          class="ticket_modal_body"
          @click.stop="closeModal"
        >
          <!-- ticket -->
          <div class="ticket ticket_acquired_modal_ticket" :class="designClass">
            <!-- left -->
            <div class="ticket_left">
              <div class="ticket_left_logo" />
              <div class="ticket_left_title">Coupon</div>
            </div>

            <!-- center -->
            <div class="ticket_center">
              <i class="ticket_center_line"></i>
            </div>

            <!-- right -->
            <div class="ticket_right">
              <div class="ticket_right_title">
                <div class="ticket_right_title_base">{{ ticket.title }}</div>
              </div>

              <div class="ticket_right_num">
                {{ discountAmount
                }}<span class="ticket_right_num_half">OFF</span>
              </div>

              <div class="ticket_right_rule">
                <div class="ticket_right_rule_left">
                  <dl class="ticket_right_rule_dl ticket_right_rule_dl_bold">
                    <div class="ticket_right_rule_dl_layout">
                      <dt class="ticket_right_rule_dt">最低利用金額</dt>
                      <dd class="ticket_right_rule_dd">{{ minNeedPrice }}</dd>
                    </div>
                    <div
                      class="ticket_right_rule_dl_layout ticket_right_rule_dl_bold"
                    >
                      <dt class="ticket_right_rule_dt">有効期限</dt>
                      <dd class="ticket_right_rule_dd">
                        <span>{{ formattedCanUseDate }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <!-- ticket close -->
        </div>
        <div class="ticket_acquired_modal_footer">
          <div>
            <ul>
              <li v-for="b in buttons" :key="b.label">
                <input
                  type="button"
                  class="ticket_acquired_modal_footer_button"
                  :class="b.class"
                  :value="b.label"
                  @click="b.onClick"
                />
              </li>
            </ul>
          </div>
        </div>
        <slot name="additional_content" />
      </div>
    </div>

    <div id="fav-container" ref="favContainer" class="fav_container">
      <canvas ref="canvas" />
      <div ref="animField" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import confetti from '@/plugins/confetti';

export default {
  name: 'TicketAcquiredModal',
  components: {},
  props: {
    isShow: Boolean,
    ticket: {
      type: Object,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
    delayOfAnimetion: {
      type: Number,
      required: true,
    },
    buttons: {
      type: Array,
      required: true,
    },
    messages: {
      type: Array,
      default: () => [],
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      didReceiveIAM: false,
    };
  },
  computed: {
    designClass() {
      if (this.ticket == null) {
        return '';
      }

      return `ticket_design_${this.ticket.design_type}`;
    },
    formattedCanUseDate() {
      if (this.ticket == null) {
        return '';
      }

      return moment.unix(this.ticket.can_use_date_to).format('YYYY年MM月DD日');
    },
    discountAmount() {
      if (this.ticket == null) {
        return '';
      }

      return this.ticket.discount_type === 1
        ? `¥${this.ticket.amount}`
        : `${this.ticket.amount}%`;
    },
    minNeedPrice() {
      if (this.ticket == null || this.ticket.min_need_price == null) {
        return 'なし';
      }

      return `¥${this.ticket.min_need_price}`;
    },
  },
  watch: {
    isShow(newIsShow, oldIsShow) {
      // ほとんどeslint避け
      if (newIsShow === oldIsShow) {
        return;
      }

      if (newIsShow) {
        const { braze } = window;
        if (braze) {
          braze.logCustomEvent('ticket_acquired');
        }
      } else {
        // 花吹雪を表示フィールドを非表示に
        this.$refs.canvas.hidden = true;
        this.$refs.animField.hidden = true;
        this.didReceiveIAM = false;
      }
    },
  },
  mounted() {
    const { braze } = window;
    if (braze) {
      const self = this;
      braze.interceptInAppMessage = function interceptInAppMessage(
        inAppMessage,
      ) {
        if (inAppMessage.extras.is_ticket_acquired_campaign === 'true') {
          self.didReceiveIAM = true;
          // 花吹雪開始
          self.$refs.canvas.hidden = false;
          self.$refs.animField.hidden = false;
          self.runAnimation();
          setTimeout(() => {
            confetti.CONFETTI(self.$refs.canvas);
          }, self.delayOfAnimetion);

          return true;
        }
        return false;
      };
    }
  },
  methods: {
    closeModal() {
      this.onClose();
    },
    runAnimation() {
      const codeList = [
        { x: 32, y: 30 },
        { x: 344, y: 274 },
        { x: 220, y: 480 },
        { x: 320, y: 20 },
        { x: 121, y: 93 },
        { x: 20, y: 400 },
        { x: 25, y: 231 },
        { x: 50, y: 500 },
      ];
      for (let i = 1; i < codeList.length; i += 1) {
        const color = this.colors[(i - 1) % this.colors.length];
        this.appendSparkle(codeList[i].x, codeList[i].y, 7, color, i * 160);
        i += 1;
      }
    },
    appendSparkle(x, y, numOfLine, color, delay) {
      const fav = document.createElement('div');
      fav.classList.add('fav');
      fav.setAttribute('style', `left: ${x}px; top: ${y}px;`);

      const favSparkle = document.createElement('span');
      favSparkle.classList.add('fav-sparkle');
      fav.appendChild(favSparkle);

      for (let i = 0; i < numOfLine; i += 1) {
        const line = document.createElement('span');
        line.classList.add('fav-sparkle-i');
        line.setAttribute('style', `background:${color};`);

        favSparkle.appendChild(line);
      }

      setTimeout(() => {
        this.$refs.animField.appendChild(fav);
        setTimeout(() => {
          fav.remove();
        }, 1000);
      }, delay);
    },
  },
};
</script>
<style scoped>
.fav_container {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 10001;
}

.fav_container .anim_field {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

#ticket-acquired-ticket-modal-body {
  background-color: transparent;
  height: 270px;
}

.ticket_acquired_modal_header p {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
}

.ticket_acquired_modal_ticket {
  margin: auto;
}

.ticket_acquired_modal_ticket .ticket_center_line::before {
  background: url('~@/assets/img/ticket/modal_back.png');
}

.ticket_acquired_modal_ticket .ticket_center_line::after {
  background: url('~@/assets/img/ticket/modal_back.png');
}

.ticket_acquired_modal_footer {
  text-align: center;
}

.ticket_acquired_modal_footer li {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  padding: 6px;
}

.ticket_acquired_modal_footer .ticket_acquired_modal_footer_button {
  border-radius: 2px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 14px;
  width: 335px;
}

.ticket_acquired_modal_footer .ticket_acquired_modal_footer_button.primary {
  background-color: #fff;
  border: none;
  color: #000;
}

.ticket_acquired_modal_footer .ticket_acquired_modal_footer_button.secondary {
  background-color: transparent;
  border-color: #fff;
  border-style: solid;
  color: #fff;
}

@media only screen and (max-width: 667px) {
  #ticket-acquired-ticket-modal-body {
    height: 305px;
  }

  .ticket_acquired_modal_footer {
    padding: 0 32px;
  }

  .ticket_acquired_modal_footer .ticket_acquired_modal_footer_button {
    width: 100%;
  }
}

@media only screen and (max-height: 600px) {
  .ticket_acquired_modal {
    overflow-y: scroll;
  }
}
</style>
<style src="@/assets/css/ticket/ticket.css" scoped></style>
