import client from '@/modules/api/createClient';
import security from '@/modules/api/php/security';
import { buildUrl } from '@/modules/api/buildUrl';
import moment from 'moment';

const fetchIsLogin = () =>
  client.phpApiClient.get(
    buildUrl(`/userstatus/is_logined`, { timestamp: moment().unix() }),
  );

const fetchMe = () =>
  client.phpApiClient.get(buildUrl(`/me`, { timestamp: moment().unix() }));

const updateMeForPaymentAddress = params => security.securePost(`/me`, params);

export default {
  fetchIsLogin,
  fetchMe,
  updateMeForPaymentAddress,
};
