<script setup>
import { computed, inject, toValue } from 'vue';
import { useRoute } from 'vue-router';
import { MIcon, MText, useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import AppLink from '@/components/common/AppLink';
import { ROUTES } from '@/consts/navigation';

const route = useRoute();
const { smAndUp } = useDisplay();

const heading = inject('heading');

const breadcrumbs = computed(() => [
  {
    title: ROUTES.HOME.meta.heading,
    to: { name: ROUTES.HOME.name },
  },
  ...(route.meta.breadcrumbs || []),
  { title: toValue(heading) },
]);
</script>

<template>
  <ul v-if="smAndUp" class="breadcrumb-container flex flex-wrap gap-1">
    <MText
      v-for="(breadcrumb, key) in breadcrumbs"
      :key
      class="flex items-center"
      size="small"
      tag="li"
    >
      <AppLink
        v-if="breadcrumb.to"
        :to="breadcrumb.to"
        :tracking-id="`breadcrumb_${breadcrumb.to.name}`"
      >
        {{ breadcrumb.title }}
      </AppLink>
      <span v-else class="text-secondary">{{ breadcrumb.title }}</span>
      <MIcon
        v-if="key < breadcrumbs.length - 1"
        class="ml-1"
        name="right"
        size="small"
      />
    </MText>
  </ul>
</template>
