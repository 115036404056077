<script setup>
import { inject } from 'vue';
import { useRoute } from 'vue-router';
import Breadcrumbs from '@/components/discover/Breadcrumbs';
import SectionTitle from '@/components/discover/SectionTitle';

const route = useRoute();
const heading = inject('heading');
</script>

<template>
  <header class="flex flex-wrap items-center gap-y-6 gap-x-2 sm:gap-x-4 mb-6">
    <Breadcrumbs v-if="!route.meta.hideBreadcrumbs" class="w-full" />
    <SectionTitle :title="heading" />
    <RouterView name="header" />
  </header>
  <RouterView />
</template>
