<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { MIcon, MText } from '@ca-crowdfunding/makuake-ui-n';
import AppLink from '@/components/common/AppLink';
import { ROUTES } from '@/consts/navigation';

const route = useRoute();
const links = computed(() =>
  [
    { to: { name: ROUTES.HISTORY.name }, text: '閲覧履歴' },
    { to: { name: ROUTES.LIKED.name }, text: '気になる' },
    { to: { name: ROUTES.MY_TAG.name }, text: 'Myタグ' },
  ].filter(link => link.to.name !== route.name),
);
</script>

<template>
  <ul class="flex justify-between">
    <li v-for="(link, key) in links" :key>
      <AppLink :to="link.to" :tracking-id="`link_${link.to.name}`">
        <MText color="info" tag="span">
          <MIcon v-if="key === 0" name="left" />{{ link.text
          }}<MIcon v-if="key === links.length - 1" name="right" />
        </MText>
      </AppLink>
    </li>
  </ul>
</template>
