import { createApp, ref } from 'vue';
import router from '@/router/home';
import Home from '@/apps/Home';
import '@ca-crowdfunding/makuake-ui-n/style.css';

const mountId = 'vue-home';
const mountElement = document.getElementById(mountId);
const featureFlag = Boolean(mountElement?.dataset?.featureFlag);
const app = createApp(Home, { featureFlag });
app.use(router);

// アプリケーション全体で保持するデータを提供する
// pinia への移行を検討する
app.provide('categories', ref([]));
app.provide('heading', ref(''));

app.mount(`#${mountId}`);
