<script setup>
import ProjectListBase from '@/components/home/ProjectListBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import { SECTION_TITLE } from '@/consts/recommend';

const props = defineProps({
  displayNumber: {
    type: Number,
    required: true,
  },
  displayRows: {
    type: Number,
  },
});

const { fetchRecommendedProjects, loading, projects, userId } =
  useFetchProjects();
const { setupIntersection } = useIntersection();
setupIntersection(() =>
  fetchRecommendedProjects({ perPage: props.displayNumber }),
);
</script>

<template>
  <ProjectListBase
    class="recommended-section"
    label="recommend"
    :display-number
    :display-rows
    :loading
    :projects
    :title="SECTION_TITLE"
  >
    <input id="recommend_user_id" type="hidden" :value="userId" />
  </ProjectListBase>
</template>
