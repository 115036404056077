import { computed, toValue } from 'vue';
import useFirstVisitForABTest from '@/composables/common/firstVisitForABTest';

/**
 * TODO:あなたへのおすすめセクション表示件数のABテスト（SP）が終わったら削除
 * https://makuake.atlassian.net/browse/PX-2751
 * @param {Ref<boolean>|boolean} isSP
 * @returns {Object}
 */
export default function useABTestPX2751(isSP) {
  const testName = 'PX2751';
  const variants = [
    { variant: 'A', weight: 1 },
    { variant: 'B', weight: 1 },
    { variant: 'C', weight: 1 },
    { variant: 'D', weight: 1 },
  ];

  const { getSavedVariant } = useFirstVisitForABTest(testName, variants);

  const px2751Variant = computed(() => {
    if (!toValue(isSP)) return undefined;
    return getSavedVariant();
  });

  const px2751DisplayNumber = computed(() => {
    switch (px2751Variant.value) {
      case 'A':
        return 10;
      case 'B':
        return 4;
      case 'C':
        return 8;
      case 'D':
        return 16;
      default:
        return 10;
    }
  });

  const isPX2751NonDefaultDisplay = computed(
    () =>
      toValue(isSP) &&
      px2751Variant.value &&
      ['B', 'C', 'D'].includes(px2751Variant.value),
  );
  return { isPX2751NonDefaultDisplay, px2751DisplayNumber, px2751Variant };
}
