<script setup>
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';

const { fetchSelectedOwnersProjects, loading, pagination, projects } =
  useFetchProjects();
</script>

<template>
  <ViewBase
    v-model:pagination="pagination"
    class="selected-owners-view"
    :loading
    :projects
    @fetch="fetchSelectedOwnersProjects({ page: pagination.page })"
  />
</template>
