<script setup>
import { nextTick, onMounted, ref, watch } from 'vue';
import { MText, MIcon } from '@ca-crowdfunding/makuake-ui-n';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useLikedProjectIds from '@/composables/common/likedProjectIds';

const { fetchLikedProjects, loading, pagination, projects } =
  useFetchProjects();
const { likedIds, loadLikedProjectIds } = useLikedProjectIds();
const offsetSub = ref(0);

onMounted(async () => {
  // 気になるリストの取得完了を待機
  await loadLikedProjectIds();

  // 気になるリストの変更を監視してページングのオフセットを調整
  watch(
    () => likedIds.value.length,
    async (newValue, oldValue) => {
      // 気になるが増えた場合
      if (newValue > oldValue && offsetSub.value > 0) {
        await nextTick();
        // Redis 上のデータと表示中の気になる済プロジェクトの数が一致した場合は最終ページに到達しているか、
        // データに不整合があった場合とみなして中断する
        if (newValue === projects.value.length - offsetSub.value) return;

        offsetSub.value -= 1;
        return;
      }
      // 気になるが減った場合
      if (newValue < oldValue) {
        offsetSub.value += 1;
      }
    },
  );
});
</script>

<template>
  <ViewBase
    v-model:pagination="pagination"
    class="liked-view"
    :loading
    :projects
    @fetch="
      fetchLikedProjects({
        offsetSub: Math.max(0, offsetSub),
        page: pagination.page,
      })
    "
  >
    <template #empty>
      <div class="flex flex-col items-center gap-6 text-center">
        <MIcon color="red" name="heartFill" size="large" />
        <MText weight="bold" tag="p">プロジェクトはありません</MText>
        <MText color="secondary" size="small" tag="p"
          >一覧やプロジェクトページから登録すると、ここで見ることができます。</MText
        >
      </div>
    </template>
  </ViewBase>
</template>
