export default function useLink() {
  const BLANK = '_blank';
  const SELF = undefined;

  /**
   * 与えられたURLに基づいてリンクの情報をまとめたオブジェクトを返します。
   * @param {string} url 判定するためのURL
   * @returns {Object}
   */
  const resolveLink = url => {
    try {
      const current = window.location;
      const target = new URL(url, current.origin);
      const obj = {
        isCurrent:
          current.origin === target.origin &&
          current.pathname === target.pathname,
      };
      obj.target = current.hostname === target.hostname ? SELF : BLANK;
      return obj;
    } catch (e) {
      return { isCurrent: false, target: SELF };
    }
  };

  return {
    BLANK,
    SELF,
    resolveLink,
  };
}
