<script setup>
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import { PROJECT_DISPLAY_NUMBER } from '@/consts/home';

const { fetchPickupProjects, loading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(() =>
  fetchPickupProjects({ perPage: PROJECT_DISPLAY_NUMBER }),
);
</script>

<template>
  <ProjectSectionBase
    href="/discover/pickup"
    label="pickup"
    :loading
    :projects
    title="今日のピックアップ"
  />
</template>
