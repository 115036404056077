<script setup>
import { inject } from 'vue';
import { useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import Breadcrumbs from '@/components/discover/Breadcrumbs';
import SectionTitle from '@/components/discover/SectionTitle';
import ViewBase from '@/components/discover/ViewBase';
import FavoriteLinks from '@/components/favorite/FavoriteLinks';
import useDevice from '@/composables/common/device';
import useFetchProjects from '@/composables/common/fetchProjects';
import { SECTION_TITLE } from '@/consts/recommend';

const { app, loaded } = useDevice();
const { fetchRecommendedProjects, loading, pagination, projects } =
  useFetchProjects();

const heading = inject('heading');
const { smAndDown } = useDisplay();
</script>

<template>
  <header v-if="loaded && !app" class="flex flex-col gap-6 mb-6">
    <Breadcrumbs />
    <SectionTitle :title="heading" />
    <FavoriteLinks v-if="smAndDown" />
  </header>
  <RouterView />
  <SectionTitle class="mt-12 mb-6" tag="h3" :title="SECTION_TITLE" />
  <ViewBase
    v-model:pagination="pagination"
    :loading
    :projects
    @fetch="fetchRecommendedProjects"
  />
</template>
