<script setup>
import { MDivider, MText } from '@ca-crowdfunding/makuake-ui-n';
import AppLink from '@/components/common/AppLink';
import useLocation from '@/composables/navigation/location';
import { ROUTES } from '@/consts/navigation';

const { getAreas } = useLocation();
const areas = getAreas();
</script>

<template>
  <dl class="locations-all">
    <template v-for="(area, key) in areas" :key>
      <MText class="py-6" size="large" sm="x-large" weight="bold" tag="dt">
        <AppLink
          :to="{
            name: ROUTES.LOCATION.name,
            params: {
              locationIds: area.locations.map(l => l.id).join(),
            },
          }"
          :tracking-id="`location_${area.locations.map(l => l.id).join('_')}`"
        >
          {{ area.name }}
        </AppLink>
      </MText>
      <MDivider color="tertiary" />
      <div
        class="prefectures grid sm:flex gap-6 sm:gap-8 mb-4 sm:mb-6 py-6 sm:py-4"
      >
        <MText v-for="location in area.locations" :key="location.id" tag="dd">
          <AppLink
            :to="{
              name: ROUTES.LOCATION.name,
              params: { locationIds: location.id },
            }"
            :tracking-id="`location_${location.id}`"
          >
            {{ location.name }}
          </AppLink>
        </MText>
      </div>
    </template>
  </dl>
</template>

<style scoped>
.prefectures {
  grid-template-columns: repeat(auto-fill, minmax(2.5rem, 1fr));
  white-space: nowrap;
}

a:focus-visible {
  outline: auto;
  outline-color: #4ab3df;
}
</style>
