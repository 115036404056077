import Cookies from 'js-cookie';

export default {
  /**
   * 気になるしたプロジェクトのIDを配列で返却する
   *
   * @returns {Array}
   */
  listIds: () => {
    const fromCookie = Cookies.get('is_good');
    if (typeof fromCookie !== 'string') {
      return [];
    }
    return fromCookie.split(',');
  },
};
