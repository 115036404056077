import { inject, ref } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import AllTimeRankingView from '@/components/discover/AllTimeRankingView';
import AllView from '@/components/discover/AllView';
import CategoryView from '@/components/discover/CategoryView';
import ComingSoonView from '@/components/discover/ComingSoonView';
import DiscoverView from '@/components/discover/DiscoverView';
import EndingSoonView from '@/components/discover/EndingSoonView';
import GovernmentView from '@/components/discover/GovernmentView';
import LocationsAllView from '@/components/discover/LocationsAllView';
import LocationView from '@/components/discover/LocationView';
import MyTagButton from '@/components/discover/MyTagButton';
import NewView from '@/components/discover/NewView';
import NotFoundView from '@/components/discover/NotFoundView';
import PickupView from '@/components/discover/PickupView';
import ProjectsView from '@/components/discover/ProjectsView';
import RankingView from '@/components/discover/RankingView';
import SelectedOwnersView from '@/components/discover/SelectedOwnersView';
import TagsAllView from '@/components/discover/TagsAllView';
import TagView from '@/components/discover/TagView';
import FavoriteView from '@/components/favorite/FavoriteView';
import HistoryView from '@/components/favorite/HistoryView';
import LikedView from '@/components/favorite/LikedView';
import MyTagView from '@/components/favorite/MyTagView';
import HomeView from '@/components/home/HomeView';
import useMeta from '@/composables/common/meta';
import useTag from '@/composables/common/tag';
import useLocation from '@/composables/navigation/location';
import {
  META_DESCRIPTION,
  ROUTES,
  SITE_TITLE,
  SUFFIX,
} from '@/consts/navigation';
import tagApi from '@/modules/api/v1/tag';
import categoryApi from '@/modules/api/v2/categories';

const { getTitleByIds } = useLocation();
const { generateDescription } = useMeta();
const { popularTags, tags } = useTag();

const routes = [
  {
    ...ROUTES.HOME,
    component: HomeView,
  },
  {
    ...ROUTES.DISCOVER,
    component: DiscoverView,
    children: [
      {
        ...ROUTES.ALL,
        component: AllView,
      },
      {
        ...ROUTES.ALL_TIME_RANKING,
        component: AllTimeRankingView,
      },
      {
        ...ROUTES.CATEGORY,
        component: CategoryView,
        meta: {
          breadcrumbs: [{ title: 'カテゴリー' }],
        },
      },
      {
        ...ROUTES.COMING_SOON,
        component: ComingSoonView,
      },
      {
        ...ROUTES.ENDING_SOON,
        component: EndingSoonView,
      },
      {
        ...ROUTES.GOVERNMENT,
        component: GovernmentView,
      },
      {
        ...ROUTES.LOCATIONS_ALL,
        component: LocationsAllView,
      },
      {
        ...ROUTES.LOCATION,
        component: LocationView,
        meta: {
          breadcrumbs: [
            {
              title: ROUTES.LOCATIONS_ALL.meta.heading,
              to: { name: ROUTES.LOCATIONS_ALL.name },
            },
          ],
        },
      },
      {
        ...ROUTES.NEW,
        component: NewView,
      },
      {
        ...ROUTES.PICKUP,
        component: PickupView,
      },
      {
        ...ROUTES.PROJECTS,
        component: ProjectsView,
      },
      {
        ...ROUTES.RANKING,
        component: RankingView,
      },
      {
        ...ROUTES.SELECTED_OWNERS,
        component: SelectedOwnersView,
      },
      {
        ...ROUTES.TAGS_ALL,
        component: TagsAllView,
      },
      {
        ...ROUTES.TAG,
        components: {
          default: TagView,
          header: MyTagButton,
        },
        meta: {
          breadcrumbs: [
            {
              title: ROUTES.TAGS_ALL.meta.heading,
              to: { name: ROUTES.TAGS_ALL.name },
            },
          ],
        },
        props: true,
      },
      { ...ROUTES.NOT_FOUND, component: NotFoundView },
    ],
  },
  {
    ...ROUTES.FAVORITE,
    component: FavoriteView,
    children: [
      {
        ...ROUTES.HISTORY,
        component: HistoryView,
      },
      {
        ...ROUTES.LIKED,
        component: LikedView,
      },
      {
        ...ROUTES.MY_TAG,
        component: MyTagView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async to => {
  const { meta, name, params } = to;
  const heading = inject('heading', ref(''));

  if (name === ROUTES.CATEGORY.name) {
    const categories = inject('categories', ref([]));

    await (async () => {
      try {
        if (!categories.value.length) {
          const { data } = await categoryApi.fetchAll();
          categories.value = data?.categories || [];
        }
      } catch (e) {
        categories.value = [];
      }
    })();

    const categoryName = categories.value.find(
      category => category.category_code === params.categorySlug,
    )?.name;

    meta.heading = categoryName || 'カテゴリーが不正です';
    meta.title = categoryName
      ? `${categoryName}の${SUFFIX}`
      : '不正なカテゴリー';
    meta.description = () => generateDescription(meta.title);
  } else if (name === ROUTES.TAG.name) {
    let tagName = [...popularTags, ...tags.value].find(
      tag => String(tag.id) === params.tagId,
    )?.name;

    await (async () => {
      try {
        if (!tagName) {
          const { data } = await tagApi.getTagName(params.tagId);
          tagName = data?.name;
        }
      } catch (e) {
        tagName = null;
      }
    })();

    meta.heading = tagName ? `# ${tagName}` : 'タグIDが不正です';
    meta.title = tagName ? `# ${tagName}の${SUFFIX}` : '不正なタグ';
    meta.description = () => generateDescription(meta.title);
  } else if (name === ROUTES.LOCATION.name) {
    const title = getTitleByIds(params.locationIds);
    meta.heading = title || '地域IDが不正です';
    meta.title = title ? `${title}の${SUFFIX}` : '不正な地域';
    meta.description = () => generateDescription(meta.title);
  }

  // 動的にタイトル・メタ情報を変更
  if (meta.heading) heading.value = meta.heading;
  const title = meta.title ? `${meta.title}｜${SITE_TITLE}` : SITE_TITLE;
  const description =
    typeof meta.description === 'function'
      ? meta.description()
      : META_DESCRIPTION;
  document.title = title;
  document
    .querySelector("meta[name='description']")
    .setAttribute('content', description);
});

export default router;
