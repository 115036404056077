<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <title>推奨実行者マーク</title>
    <defs>
      <mask id="selected-owner-mark-mask">
        <rect width="24" height="24" fill="white" />
        <path
          d="M12.4157 9.27168L13.4948 11.1406L15.6209 11.5997C15.9831 11.6779 16.1237 12.1195 15.8739 12.3929L14.4188 13.9838L14.6525 16.1551C14.6924 16.525 14.3144 16.7976 13.976 16.643L12 15.7411L10.0241 16.643C9.68572 16.7976 9.30772 16.525 9.34756 16.1551L9.58132 13.9838L8.1262 12.3929C7.87612 12.1195 8.017 11.6779 8.37916 11.5997L10.5053 11.1406L11.5844 9.27168C11.7692 8.95176 12.2309 8.95176 12.4157 9.27168Z"
        />
      </mask>
      <linearGradient
        id="selected-owner-mark-gradient-a"
        x1="3.0037"
        y1="2"
        x2="12.6485"
        y2="8.57074"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.161458" stop-color="#1AD4FF" />
        <stop offset="1" stop-color="#8793FF" />
      </linearGradient>
      <linearGradient
        id="selected-owner-mark-gradient-b"
        x1="21.042"
        y1="2"
        x2="12.0053"
        y2="9.01046"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.182292" stop-color="#FF5998" />
        <stop offset="1" stop-color="#C985FF" />
      </linearGradient>
    </defs>
    <path
      d="M12.4936 10.8212H8.14096C7.7848 10.8212 7.45528 10.6316 7.27624 10.3237L3.31264 3.5024C2.92528 2.83568 3.40624 2 4.17736 2H8.53C8.88616 2 9.21568 2.1896 9.39472 2.49752L13.3586 9.3188C13.7459 9.98552 13.265 10.8212 12.4938 10.8212H12.4936Z"
      fill="url(#selected-owner-mark-gradient-a)"
    />
    <path
      d="M11.5094 10.8212H15.862C16.2182 10.8212 16.5477 10.6316 16.7268 10.3237L20.6906 3.5024C21.078 2.83568 20.597 2 19.8259 2H15.4732C15.1171 2 14.7876 2.1896 14.6085 2.49752L10.6447 9.3188C10.2573 9.98552 10.7383 10.8212 11.5094 10.8212Z"
      fill="url(#selected-owner-mark-gradient-b)"
    />
    <circle cx="12" cy="13" r="9" fill="white" />
    <clipPath id="selected-owner-mark-circle">
      <circle cx="12" cy="13" r="7" />
    </clipPath>
    <foreignObject
      width="24"
      height="24"
      clip-path="url(#selected-owner-mark-circle)"
      mask="url(#selected-owner-mark-mask)"
    >
      <div class="gradient" />
    </foreignObject>
  </svg>
</template>

<style scoped>
.gradient {
  background-image: conic-gradient(
    from 240deg,
    #8bea74,
    #4edcd5,
    #1ad4ff,
    #1ad0ff,
    #9ea8ff,
    #dd79ff,
    #ff5998,
    #ff6a42,
    #ff9811,
    #ffe600,
    #f1f504,
    #b5ed0c,
    #8bea74
  );
  background-position: 0 1px;
  height: 24px;
  width: 24px;
}
</style>
