import { ref } from 'vue';
import useSnackbar from '@/composables/common/snackbar';
import { DEFAULT_ERROR_MESSAGE } from '@/consts/error';
import api from '@/modules/api/php/tag';

const popularTags = [
  { id: 203, name: '便利グッズ' },
  { id: 8, name: 'ガジェット' },
  { id: 121, name: 'レストラン' },
  { id: 180, name: '会員制' },
  { id: 66, name: 'フード' },
  { id: 60, name: 'グルメ' },
  { id: 158, name: '日本酒' },
  { id: 77, name: '肉' },
  { id: 175, name: 'ギフト' },
  { id: 81, name: 'テクノロジー' },
  { id: 184, name: '日本製' },
  { id: 129, name: '地域活性' },
  { id: 183, name: '伝統' },
  { id: 115, name: '文房具' },
  { id: 50, name: 'ファッション' },
  { id: 57, name: '腕時計' },
  { id: 53, name: '財布' },
  { id: 52, name: 'リュック' },
  { id: 134, name: 'アウトドア' },
  { id: 109, name: '子ども' },
];

const tags = ref([]);
const isLoaded = ref(false);

export default function useTag() {
  const isLoading = ref(false);

  const { showError } = useSnackbar();

  const fetchTags = async () => {
    if (isLoading.value) return;
    isLoading.value = true;

    try {
      const { data } = await api.fetchTags();
      tags.value = data.tags?.length ? data.tags : [];
      isLoaded.value = true;
    } catch (e) {
      showError({ message: DEFAULT_ERROR_MESSAGE });
    } finally {
      isLoading.value = false;
    }
  };

  return {
    fetchTags,
    isLoaded,
    isLoading,
    popularTags,
    tags,
  };
}
