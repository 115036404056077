<template>
  <ticket-acquired-modal
    :is-show="isShowModal"
    :ticket="acquiredTicket"
    :buttons="buttons"
    :colors="colors"
    :delay-of-animetion="delayOfAnimetion"
    :messages="messages"
    :on-close="closeModal"
  />
</template>

<script>
import TicketAcquiredModal from '@/components/ticket/TicketAcquiredModal';
import friendInviteModule from '@/modules/friendInvite';
import { MK_COLORS, DELAY_OF_ANIMATION } from '@/consts/ticket';

export default {
  name: 'FriendInviteTicketAcquireModal',
  components: {
    TicketAcquiredModal,
  },
  props: {},
  data() {
    return {
      isShowModal: false,
      acquiredTicket: {
        title: '',
        discount_type: 1,
        amount: 0,
        count: 1,
        can_use_date_to: 0,
        design_type: 303,
      },
      colors: MK_COLORS,
      delayOfAnimetion: DELAY_OF_ANIMATION,
      buttons: [
        {
          class: 'primary',
          label: '受け取ったクーポンを確認する',
          onClick: this.goTicketPage,
        },
        {
          class: 'secondary',
          label: '閉じる',
          onClick: this.closeModal,
        },
      ],
      messages: ['応援クーポンを受け取りました！'],
    };
  },
  computed: {},
  created() {},
  beforeMount() {},
  mounted() {
    friendInviteModule.postFriendInviteCode(this.onSuccessHandler);
  },
  methods: {
    onSuccessHandler(response) {
      this.acquiredTicket = response.data;
      this.showModal();
    },
    showModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
    goTicketPage() {
      window.location.href = '/my/ticket/';
    },
  },
};
</script>
