import client, { createClient } from '@/modules/api/createClient';
import { buildUrl } from '@/modules/api/buildUrl';

const fetchPopular = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = { type: 'popular' };

  const url = buildUrl('/v2/returns', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchReturn = returnId =>
  client.makuakeApiClient.get(`/v2/returns/${returnId}`);

const fetchReturnQuestions = returnId =>
  client.makuakeApiClient.get(`/v2/returns/${returnId}/questions`);

export default {
  fetchPopular,
  fetchReturn,
  fetchReturnQuestions,
};
