<script setup>
import { computed } from 'vue';
import { MButton, MText } from '@ca-crowdfunding/makuake-ui-n';

const props = defineProps({ loggedIn: { type: Boolean, required: true } });
const emit = defineEmits(['edit']);

const href = computed(() =>
  props.loggedIn
    ? undefined
    : `/login?ref=${encodeURIComponent(window.location.href)}`,
);

const text = computed(() => (props.loggedIn ? 'Myタグを登録' : 'ログイン'));

const onClick = () => {
  if (props.loggedIn) emit('edit');
};
</script>

<template>
  <div class="text-center">
    <MText class="mb-6" tag="h3" weight="bold"
      >プロジェクトを見逃さないために</MText
    >
    <MText class="mb-10" color="secondary" size="small"
      >興味のあるタグを登録しましょう。<br />
      タグが付けられたプロジェクトの開始時に通知します。</MText
    >
    <MButton class="hover:no-underline" :href rounded @click="onClick">{{
      text
    }}</MButton>
  </div>
</template>

<style scoped>
.hover\:no-underline:hover {
  text-decoration-line: none;
}
</style>
